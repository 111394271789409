import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import variables from '../../../utils/variables';
import { setLongDescription } from '../../../actions/ads';

const LongDescriptionTextField = (props) => {
    const handleChange = (value) => {
        props.onChange(value, true);
    };

    return (
        <TextField
            multiline
            id="project_twitter"
            name="project_twitter"
            placeholder={variables[props.lang].write_long_description}
            rows={4}
            type=""
            value={props.value}
            onChange={handleChange}/>
    );
};

LongDescriptionTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.ads.longDescription.value,
    };
};

const actionsToProps = {
    onChange: setLongDescription,
};

export default connect(stateToProps, actionsToProps)(LongDescriptionTextField);
