import withRouter from '../../components/WithRouter';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonBase, Popover, styled, Tooltip } from '@mui/material';
import ClassNames from 'classnames';
import variables from '../../utils/variables';
import { ReactComponent as ArrowDown } from '../../assets/arrow-down.svg';
import './index.css';
import logo from '../../assets/logo.svg';
import { showSignInDialog } from '../../actions/signIn';
import SignIn from '../SignIn';
import { clearCreateOrder, setTicketsData } from '../../actions/buyTickets';
import { fetchBalance } from '../../actions/account/BCDetails';
import { fetchProfileDetails, fetchProfileImage, fetchStats, setTabValue } from '../../actions/myAccount';
import ExpansionButton from './ExpansionButton';
import Icon from '../../components/Icon';
import { hideSideBar } from '../../actions/navBar';
import { setDisconnect } from '../../actions/account/wallet';

const ProfileTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} leaveDelay={200}/>
))(({ theme }) => ({
    zIndex: theme.zIndex.tooltip + 1,
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'unset',
        marginTop: '5px !important',
    },
}));

class NavBar extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            anchorEl: null,
            tooltipOpen: false,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handlePopover = this.handlePopover.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleCloseNav = this.handleCloseNav.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.handleDisconnect = this.handleDisconnect.bind(this);
        this.handleCloseTooltip = this.handleCloseTooltip.bind(this);
        this.handleOpenTooltip = this.handleOpenTooltip.bind(this);
    }

    componentDidMount () {
        const route = this.props.router.location && this.props.router.location.pathname.split('/') &&
            this.props.router.location.pathname.split('/')[1];
        this.setState({ activeState: route });
        if (this.props.profileDetails && !Object.keys(this.props.profileDetails).length &&
            !this.props.profileDetailsInProgress && localStorage.getItem('acToken_cosmoverse_2024')) {
            this.props.fetchProfileDetails((result) => {
                // if (result && result.bc_account && result.bc_account.address) {
                //     this.props.fetchBalance(result.bc_account.address);
                // } else if (!result) {
                //     localStorage.clear();
                // }
            });
            this.props.fetchStats();
            this.props.fetchProfileImage();
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            setTimeout(() => {
                const route = this.props.router.location && this.props.router.location.pathname.split('/') &&
                    this.props.router.location.pathname.split('/')[1];
                this.setState({ activeState: route });
                if (!this.props.profileDetailsInProgress && localStorage.getItem('acToken_cosmoverse_2024')) {
                    this.props.fetchProfileDetails((result) => {
                        // if (result && result.bc_account && result.bc_account.address) {
                        //     this.props.fetchBalance(result.bc_account.address);
                        // }
                    });
                    this.props.fetchStats();
                    this.props.fetchProfileImage();
                }
            }, 400);
        }
    }

    handlePopover (event) {
        this.setState({
            anchorEl: event.currentTarget,
        });
    }

    handleCloseTooltip () {
        this.setState({
            tooltipOpen: false,
        });
    }

    handleOpenTooltip () {
        this.setState({
            tooltipOpen: true,
        });
    }

    handleClick (item) {
        // if (this.props.router.location && this.props.router.location.pathname.indexOf('payment') > -1) {
        //     this.props.clearCreateOrder();
        //     this.props.setTicketsData({
        //         ticket1: {
        //             name: '',
        //             email: '',
        //             email_valid: true,
        //             name_valid: true,
        //         },
        //     });
        // }

        this.props.router.navigate('/myAccount');
        this.props.setTabValue(item);
        this.handleClose();
        this.handleCloseTooltip();
    }

    handleClose () {
        this.setState({
            anchorEl: null,
        });
    }

    handleSignIn () {
        // if (this.props.show) {
        //     this.props.handleClose();
        //     document.body.style.overflow = null;
        // }

        this.props.showSignInDialog();
    }

    handleCloseNav () {
        if (this.props.show) {
            this.props.handleClose();
            document.body.style.overflow = null;
        }
    }

    handleRedirect () {
        this.props.router.navigate('/');
        this.props.clearCreateOrder();
    }

    handleDisconnect () {
        if (this.props.show) {
            this.props.handleClose();
            document.body.style.overflow = null;
        }

        this.props.setDisconnect();
        localStorage.clear();
        this.props.router.navigate('/');
        this.handleClose();
        this.handleCloseTooltip();
    }

    render () {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        const route = this.props.router.location && this.props.router.location.pathname.split('/') &&
            this.props.router.location.pathname.split('/')[1];

        let ordersCount = 0;
        if (this.props.stats && this.props.stats.orders) {
            ordersCount = ordersCount + Number(this.props.stats.orders);
        }
        if (this.props.stats && this.props.stats.slot_orders) {
            ordersCount = ordersCount + Number(this.props.stats.slot_orders);
        }

        return (
            <div className="nav_bar">
                <div>
                    <div className="left_section" onClick={this.handleRedirect}>
                        <img alt="logo" src={logo}/>
                    </div>
                    <ExpansionButton/>
                    <div className={ClassNames('right_section', this.props.show ? 'show' : '')}>
                        <div className="back_button" onClick={this.handleCloseNav}>
                            <Icon className="cross" icon="cross"/>
                        </div>
                        {route && route === 'myAccount'
                            ? <Button
                                className="buy_tickets"
                                onClick={this.handleRedirect}>
                                {variables[this.props.lang]['buy_tickets']}
                            </Button>
                            : route && route === 'tickets'
                                ? <Button
                                    className="buy_tickets ticket_types"
                                    onClick={this.handleRedirect}>
                                    {variables[this.props.lang]['ticket_types']}
                                </Button>
                                : null}
                        {this.props.profileDetails && Object.keys(this.props.profileDetails).length
                            ? <>
                                <div
                                    className={open ? 'hide_popover connected_button mobile' : 'connected_button mobile'}>
                                    <ButtonBase
                                        aria-describedby={id}
                                        className="profile_button"
                                        onClick={this.handlePopover}>
                                        {/* {this.props.profileImage */}
                                        {/*     ? <img alt="profile" className="profile_image" src={this.props.profileImage}/> */}
                                        {/*     : null} */}
                                        <div>
                                            <p className="name">
                                                {this.props.profileDetails && this.props.profileDetails.name
                                                    ? this.props.profileDetails.name : null}
                                            </p>
                                            <p
                                                className="email"
                                                title={this.props.profileDetails && this.props.profileDetails.email_address}>
                                                {this.props.profileDetails && this.props.profileDetails.email_address}
                                            </p>
                                        </div>
                                        <ArrowDown/>
                                    </ButtonBase>
                                </div>
                                <ProfileTooltip
                                    open={this.state.tooltipOpen}
                                    placement={'bottom-end'}
                                    title={
                                        <div className="popover_section">
                                            <Button onClick={() => this.handleClick('tickets')}>
                                                {variables[this.props.lang].tickets}
                                                {this.props.stats && this.props.stats.tickets
                                                    ? <span>{this.props.stats.tickets}</span> : ''}
                                            </Button>
                                            <Button onClick={() => this.handleClick('nfts')}>
                                                {variables[this.props.lang].nfts}
                                                {this.props.stats && this.props.stats.claimed_tickets
                                                    ? <span>{this.props.stats.claimed_tickets}</span> : ''}
                                            </Button>
                                            <Button onClick={() => this.handleClick('orders')}>
                                                {variables[this.props.lang].orders}
                                                {ordersCount
                                                    ? <span>{ordersCount}</span> : ''}
                                            </Button>
                                            <Button onClick={() => this.handleClick('drops')}>
                                                {variables[this.props.lang].drops}
                                                {this.props.stats && this.props.stats.total_drops
                                                    ? <span>{this.props.stats.total_drops}</span> : ''}
                                            </Button>
                                            <Button onClick={() => this.handleClick('ads')}>
                                                {variables[this.props.lang].ads}
                                            </Button>
                                            <Button onClick={() => this.handleClick('my_ad_slots')}>
                                                {variables[this.props.lang].my_ads_slots}
                                                {this.props.stats && this.props.stats.owned_slots
                                                    ? <span>{this.props.stats.owned_slots}</span> : ''}
                                            </Button>
                                            <Button
                                                className="logout"
                                                onClick={this.handleDisconnect}>{variables[this.props.lang].logout}</Button>
                                        </div>}
                                    onClose={this.handleCloseTooltip} onOpen={this.handleOpenTooltip}>
                                    <div
                                        className={open ? 'hide_popover connected_button web' : 'connected_button web'}>
                                        <ButtonBase
                                            aria-describedby={id}
                                            className="profile_button">
                                            {/* onClick={this.handlePopover}> */}
                                            {/* {this.props.profileImage */}
                                            {/*     ? <img alt="profile" className="profile_image" src={this.props.profileImage}/> */}
                                            {/*     : null} */}
                                            <div>
                                                <p className="name">
                                                    {this.props.profileDetails && this.props.profileDetails.name
                                                        ? this.props.profileDetails.name : null}
                                                </p>
                                                <p
                                                    className="email"
                                                    title={this.props.profileDetails && this.props.profileDetails.email_address}>
                                                    {this.props.profileDetails && this.props.profileDetails.email_address}
                                                </p>
                                            </div>
                                            <ArrowDown/>
                                        </ButtonBase>
                                    </div>
                                </ProfileTooltip>
                            </>
                            : <div className="connect_button">
                                <Button onClick={this.handleSignIn}>
                                    {variables[this.props.lang]['sign_in']}
                                </Button>
                            </div>}
                    </div>
                    <Popover
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        className="profile_popover"
                        id={id}
                        open={open}
                        onClose={this.handleClose}>
                        <div className="popover_section">
                            <Button onClick={() => this.handleClick('tickets')}>
                                {variables[this.props.lang].tickets}
                                {this.props.stats && this.props.stats.tickets
                                    ? <span>{this.props.stats.tickets}</span> : ''}
                            </Button>
                            <Button onClick={() => this.handleClick('nfts')}>
                                {variables[this.props.lang].nfts}
                                {this.props.stats && this.props.stats.claimed_tickets
                                    ? <span>{this.props.stats.claimed_tickets}</span> : ''}
                            </Button>
                            <Button onClick={() => this.handleClick('orders')}>
                                {variables[this.props.lang].orders}
                                {ordersCount
                                    ? <span>{ordersCount}</span> : ''}
                            </Button>
                            <Button onClick={() => this.handleClick('drops')}>
                                {variables[this.props.lang].drops}
                                {this.props.stats && this.props.stats.total_drops
                                    ? <span>{this.props.stats.total_drops}</span> : ''}
                            </Button>
                            <Button onClick={() => this.handleClick('ads')}>
                                {variables[this.props.lang].ads}
                            </Button>
                            <Button onClick={() => this.handleClick('my_ad_slots')}>
                                {variables[this.props.lang].my_ads_slots}
                                {this.props.stats && this.props.stats.owned_slots
                                    ? <span>{this.props.stats.owned_slots}</span> : ''}
                            </Button>
                            <Button
                                className="logout"
                                onClick={this.handleDisconnect}>{variables[this.props.lang].logout}</Button>
                        </div>
                    </Popover>
                </div>
                <SignIn/>
            </div>
        );
    }
}

NavBar.propTypes = {
    address: PropTypes.string.isRequired,
    clearCreateOrder: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    fetchProfileImage: PropTypes.func.isRequired,
    fetchStats: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileDetailsInProgress: PropTypes.bool.isRequired,
    profileImage: PropTypes.any.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
    setDisconnect: PropTypes.func.isRequired,
    setTabValue: PropTypes.func.isRequired,
    setTicketsData: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    showSignInDialog: PropTypes.func.isRequired,
    // showProfilePopover: PropTypes.func.isRequired,
    stats: PropTypes.object.isRequired,
    auth: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        auth: state.signIn.token.value,

        profileDetails: state.myAccount.profileDetails.value,
        profileDetailsInProgress: state.myAccount.profileDetails.inProgress,
        profileImage: state.myAccount.profileImage.value,
        stats: state.myAccount.stats.value,
        show: state.navBar.show,
    };
};

const actionToProps = {
    clearCreateOrder,
    fetchBalance,
    fetchProfileDetails,
    fetchProfileImage,
    fetchStats,
    handleClose: hideSideBar,
    setTicketsData,
    showSignInDialog,
    setTabValue,
    setDisconnect,
    // showProfilePopover,
};

export default withRouter(connect(stateToProps, actionToProps)(NavBar));
