import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { fetchTicketType, fetchTicketTypeWithID } from '../../actions/buyTickets';
import { connect } from 'react-redux';
import withRouter from '../../components/WithRouter';

const Counter = (props) => {
    const [time, setTime] = useState();
    const [status, setStatus] = useState(true);
    const [timerObj] = useState({ timer: false });

    useEffect(() => {
        const ticketInfo = props.ticketInfo;
        if (ticketInfo && !Object.keys(ticketInfo).length) {
            return;
        }

        if (ticketInfo && ticketInfo.start_time && (moment().diff(ticketInfo.start_time) < -1000)) {
            if (timerObj.timer) {
                clearTimeout(timerObj.timer);
            }

            handleTimer(new Date(ticketInfo.start_time).getTime());
        } else {
            setStatus(false);
        }

        return () => {
            if (timerObj.timer) {
                clearTimeout(timerObj.timer);
            }
        };
        /* eslint-disable */
    }, []);

    const handleTimer = (endTime) => {
        const now = new Date().getTime();
        const distance = endTime - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const obj = {
            days,
            hours,
            minutes,
            seconds,
        };

        setTime(obj);

        if (distance > 0) {
            timerObj.timer = setTimeout(() => handleTimer(endTime), 1000);
        } else {
            setStatus(false);
            if (props.router && props.router.params && props.router.params.ticketID) {
                props.fetchTicketTypeWithID(props.router.params.ticketID);
            } else {
                props.fetchTicketType();
            }
        }
    };

    return (
        <div className="section4">
            {status && time
                ? <>
                    {time && time.days
                        ? <div>
                            <p>{time.days ? time.days : '00'}</p>
                            <span>days</span>
                        </div>
                        : <div>
                            <p>00</p>
                            <span>days</span>
                        </div>}
                    {time && (time.days || time.hours)
                        ? <div>
                            <p>{time.hours ? time.hours : '00'}</p>
                            <span>hrs</span>
                        </div>
                        : <div>
                            <p>00</p>
                            <span>hrs</span>
                        </div>}
                    {time && (time.days || time.hours || time.minutes)
                        ? <div>
                            <p>{time.minutes ? time.minutes : '00'}</p>
                            <span>min</span>
                        </div>
                        : <div>
                            <p>00</p>
                            <span>min</span>
                        </div>}
                    {time && (time.days || time.hours || time.minutes || time.seconds)
                        ? <div>
                            <p>{time.seconds ? time.seconds : '00'}</p>
                            <span>secs</span>
                        </div>
                        : <div>
                            <p>00</p>
                            <span>secs</span>
                        </div>}
                </>
                : null}
        </div>
    );
};

Counter.propTypes = {
    fetchTicketType: PropTypes.func.isRequired,
    fetchTicketTypeWithID: PropTypes.func.isRequired,
    ticketInfo: PropTypes.object.isRequired,
    router: PropTypes.shape({
        params: PropTypes.shape({
            ticketID: PropTypes.string,
        }),
    }).isRequired,
};

const actionToProps = {
    fetchTicketType,
    fetchTicketTypeWithID,
};

export default withRouter(connect(null, actionToProps)(Counter));
