import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import variables from '../../../utils/variables';
import { setCompanyName } from '../../../actions/ads';

const CompanyNameTextField = (props) => {
    const handleChange = (value) => {
        props.onChange(value, true);
    };

    return (
        <TextField
            id="project_twitter"
            name="project_twitter"
            placeholder={variables[props.lang].company_name}
            type=""
            value={props.value}
            onChange={handleChange}/>
    );
};

CompanyNameTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.ads.companyName.value,
    };
};

const actionsToProps = {
    onChange: setCompanyName,
};

export default connect(stateToProps, actionsToProps)(CompanyNameTextField);
