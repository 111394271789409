export const ADS_TAB_VALUE_SET = 'ADS_TAB_VALUE_SET';
export const OVERLAY_SLOT_VALUE_SET = 'OVERLAY_SLOT_VALUE_SET';

export const ADS_SLOT_CONFIRM_DIALOG_SHOW = 'ADS_SLOT_CONFIRM_DIALOG_SHOW';
export const ADS_SLOT_CONFIRM_DIALOG_HIDE = 'ADS_SLOT_CONFIRM_DIALOG_HIDE';

export const MY_AD_SLOTS_TAB_SET = 'MY_AD_SLOTS_TAB_SET';
export const MY_AD_SESSIONS_TAB_SET = 'MY_AD_SESSIONS_TAB_SET';

export const PROJECT_NAME_SET = 'PROJECT_NAME_SET';
export const PROJECT_TWITTER_SET = 'PROJECT_TWITTER_SET';
export const SHORT_DESCRIPTION_SET = 'SHORT_DESCRIPTION_SET';
export const LONG_DESCRIPTION_SET = 'LONG_DESCRIPTION_SET';
export const COMPANY_NAME_SET = 'COMPANY_NAME_SET';
export const EMAIL_ADDRESS_SET = 'EMAIL_ADDRESS_SET';
export const CONTACT_PERSON_NAME_SET = 'CONTACT_PERSON_NAME_SET';
export const CONTACT_PERSON_EMAIL_SET = 'CONTACT_PERSON_EMAIL_SET';
export const CONTACT_PERSON_TWITTER_SET = 'CONTACT_PERSON_TWITTER_SET';
export const WEBSITE_URL_SET = 'WEBSITE_URL_SET';
export const SPONSORSHIP_TYPE_SET = 'SPONSORSHIP_TYPE_SET';
export const INDUSTRY_CATEGORY_SET = 'INDUSTRY_CATEGORY_SET';

export const SELECTED_SLOTS_SET = 'SELECTED_SLOTS_SET';
export const ADX_TABS_VALUE_SET = 'ADX_TABS_VALUE_SET';
export const BILL_BOARD_SLOTS_COUNT_SET = 'BILL_BOARD_SLOTS_COUNT_SET';
export const BILL_BOARD_SLOTS_WARNING_SET = 'BILL_BOARD_SLOTS_WARNING_SET';

// sessions api
export const FETCH_DAY_SESSIONS_SUCCESS = 'FETCH_DAY_SESSIONS_SUCCESS';
export const FETCH_DAY_SESSIONS_IN_PROGRESS = 'FETCH_DAY_SESSIONS_IN_PROGRESS';
export const FETCH_DAY_SESSIONS_ERROR = 'FETCH_DAY_SESSIONS_ERROR';

export const FETCH_DAY_SLOTS_SUCCESS = 'FETCH_DAY_SLOTS_SUCCESS';
export const FETCH_DAY_SLOTS_IN_PROGRESS = 'FETCH_DAY_SLOTS_IN_PROGRESS';
export const FETCH_DAY_SLOTS_ERROR = 'FETCH_DAY_SLOTS_ERROR';

export const DAY_SLOTS_STATUS_UPDATE_SET = 'DAY_SLOTS_STATUS_UPDATE_SET';

export const FETCH_MY_SESSIONS_SUCCESS = 'FETCH_MY_SESSIONS_SUCCESS';
export const FETCH_MY_SESSIONS_IN_PROGRESS = 'FETCH_MY_SESSIONS_IN_PROGRESS';
export const FETCH_MY_SESSIONS_ERROR = 'FETCH_MY_SESSIONS_ERROR';

export const FETCH_MY_SLOTS_SUCCESS = 'FETCH_MY_SLOTS_SUCCESS';
export const FETCH_MY_SLOTS_IN_PROGRESS = 'FETCH_MY_SLOTS_IN_PROGRESS';
export const FETCH_MY_SLOTS_ERROR = 'FETCH_MY_SLOTS_ERROR';

export const ACTIVE_MY_SLOTS_SESSION_SET = 'ACTIVE_MY_SLOTS_SESSION_SET';

export const MY_AD_SESSIONS_SELECT_ALL_SET = 'MY_AD_SESSIONS_SELECT_ALL_SET';
export const MY_AD_SESSION_SLOTS_SELECT_ALL_SET = 'MY_AD_SESSION_SLOTS_SELECT_ALL_SET';

export const SUBMIT_SUCCESS_DIALOG_SHOW = 'SUBMIT_SUCCESS_DIALOG_SHOW';
export const SUBMIT_SUCCESS_DIALOG_HIDE = 'SUBMIT_SUCCESS_DIALOG_HIDE';
