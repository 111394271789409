export const TAB_VALUE_SET = 'TAB_VALUE_SET';
export const UPDATE_NAME_VALUE_SET = 'UPDATE_NAME_VALUE_SET';
export const UPDATE_NAME_SET = 'UPDATE_NAME_SET';

export const PROFILE_DETAILS_FETCH_IN_PROGRESS = 'PROFILE_DETAILS_FETCH_IN_PROGRESS';
export const PROFILE_DETAILS_FETCH_SUCCESS = 'PROFILE_DETAILS_FETCH_SUCCESS';
export const PROFILE_DETAILS_FETCH_ERROR = 'PROFILE_DETAILS_FETCH_ERROR';

export const ORDERS_FETCH_IN_PROGRESS = 'ORDERS_FETCH_IN_PROGRESS';
export const ORDERS_FETCH_SUCCESS = 'ORDERS_FETCH_SUCCESS';
export const ORDERS_FETCH_ERROR = 'ORDERS_FETCH_ERROR';

export const TICKETS_FETCH_IN_PROGRESS = 'TICKETS_FETCH_IN_PROGRESS';
export const TICKETS_FETCH_SUCCESS = 'TICKETS_FETCH_SUCCESS';
export const TICKETS_FETCH_ERROR = 'TICKETS_FETCH_ERROR';

export const TICKET_FETCH_IN_PROGRESS = 'TICKET_FETCH_IN_PROGRESS';
export const TICKET_FETCH_SUCCESS = 'TICKET_FETCH_SUCCESS';
export const TICKET_FETCH_ERROR = 'TICKET_FETCH_ERROR';

export const TICKET_DOWNLOAD_IN_PROGRESS = 'TICKET_DOWNLOAD_IN_PROGRESS';
export const TICKET_DOWNLOAD_SUCCESS = 'TICKET_DOWNLOAD_SUCCESS';
export const TICKET_DOWNLOAD_ERROR = 'TICKET_DOWNLOAD_ERROR';

export const TICKET_CANCEL_DIALOG_SHOW = 'TICKET_CANCEL_DIALOG_SHOW';
export const TICKET_CANCEL_DIALOG_HIDE = 'TICKET_CANCEL_DIALOG_HIDE';

export const TICKET_CANCEL_IN_PROGRESS = 'TICKET_CANCEL_IN_PROGRESS';
export const TICKET_CANCEL_SUCCESS = 'TICKET_CANCEL_SUCCESS';
export const TICKET_CANCEL_ERROR = 'TICKET_CANCEL_ERROR';

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_IN_PROGRESS = 'UPDATE_PROFILE_IN_PROGRESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS';
export const UPDATE_PROFILE_IMAGE_IN_PROGRESS = 'UPDATE_PROFILE_IMAGE_IN_PROGRESS';
export const UPDATE_PROFILE_IMAGE_ERROR = 'UPDATE_PROFILE_IMAGE_ERROR';

export const FETCH_PROFILE_IMAGE_SUCCESS = 'FETCH_PROFILE_IMAGE_SUCCESS';
export const FETCH_PROFILE_IMAGE_IN_PROGRESS = 'FETCH_PROFILE_IMAGE_IN_PROGRESS';
export const FETCH_PROFILE_IMAGE_ERROR = 'FETCH_PROFILE_IMAGE_ERROR';

export const STATS_FETCH_IN_PROGRESS = 'STATS_FETCH_IN_PROGRESS';
export const STATS_FETCH_SUCCESS = 'STATS_FETCH_SUCCESS';
export const STATS_FETCH_ERROR = 'STATS_FETCH_ERROR';

export const QR_CODE_FETCH_IN_PROGRESS = 'QR_CODE_FETCH_IN_PROGRESS';
export const QR_CODE_FETCH_SUCCESS = 'QR_CODE_FETCH_SUCCESS';
export const QR_CODE_FETCH_ERROR = 'QR_CODE_FETCH_ERROR';

export const CLAIM_STATUS_FETCH_IN_PROGRESS = 'CLAIM_STATUS_FETCH_IN_PROGRESS';
export const CLAIM_STATUS_FETCH_SUCCESS = 'CLAIM_STATUS_FETCH_SUCCESS';
export const CLAIM_STATUS_FETCH_ERROR = 'CLAIM_STATUS_FETCH_ERROR';

export const CLAIM_DIALOG_SHOW = 'CLAIM_DIALOG_SHOW';
export const CLAIM_DIALOG_HIDE = 'CLAIM_DIALOG_HIDE';
export const CLAIM_ADDRESS_SET = 'CLAIM_ADDRESS_SET';

export const CLAIM_NFT_IN_PROGRESS = 'CLAIM_NFT_IN_PROGRESS';
export const CLAIM_NFT_SUCCESS = 'CLAIM_NFT_SUCCESS';
export const CLAIM_NFT_ERROR = 'CLAIM_NFT_ERROR';

export const NFT_S_FETCH_IN_PROGRESS = 'NFT_S_FETCH_IN_PROGRESS';
export const NFT_S_FETCH_SUCCESS = 'NFT_S_FETCH_SUCCESS';
export const NFT_S_FETCH_ERROR = 'NFT_S_FETCH_ERROR';

export const INVOICE_DOWNLOAD_IN_PROGRESS = 'INVOICE_DOWNLOAD_IN_PROGRESS';
export const INVOICE_DOWNLOAD_SUCCESS = 'INVOICE_DOWNLOAD_SUCCESS';
export const INVOICE_DOWNLOAD_ERROR = 'INVOICE_DOWNLOAD_ERROR';

export const BC_CONNECT_DIALOG_SHOW = 'BC_CONNECT_DIALOG_SHOW';
export const BC_CONNECT_DIALOG_HIDE = 'BC_CONNECT_DIALOG_HIDE';
export const SAME_BC_CONNECT_ERROR_SET = 'SAME_BC_CONNECT_ERROR_SET';
export const SAME_BC_CONNECT_ERROR_SET_FALSE = 'SAME_BC_CONNECT_ERROR_SET_FALSE';

export const ELIGIBLE_LIST_FETCH_SUCCESS = 'ELIGIBLE_LIST_FETCH_SUCCESS';
export const ELIGIBLE_LIST_FETCH_IN_PROGRESS = 'ELIGIBLE_LIST_FETCH_IN_PROGRESS';
export const ELIGIBLE_LIST_FETCH_ERROR = 'ELIGIBLE_LIST_FETCH_ERROR';

export const CLAIMED_LIST_FETCH_SUCCESS = 'CLAIMED_LIST_FETCH_SUCCESS';
export const CLAIMED_LIST_FETCH_IN_PROGRESS = 'CLAIMED_LIST_FETCH_IN_PROGRESS';
export const CLAIMED_LIST_FETCH_ERROR = 'CLAIMED_LIST_FETCH_ERROR';

export const DROPS_TAB_SET = 'DROPS_TAB_SET';
export const ELIGIBLE_CLAIM_DIALOG_SHOW = 'ELIGIBLE_CLAIM_DIALOG_SHOW';
export const ELIGIBLE_CLAIM_DIALOG_HIDE = 'ELIGIBLE_CLAIM_DIALOG_HIDE';
export const ELIGIBLE_CLAIM_NFT_SET = 'ELIGIBLE_CLAIM_NFT_SET';
export const ELIGIBLE_CLAIM_TYPE_SET = 'ELIGIBLE_CLAIM_TYPE_SET';

export const COLLECTION_NFTS_FETCH_SUCCESS = 'COLLECTION_NFTS_FETCH_SUCCESS';
export const COLLECTION_NFTS_FETCH_IN_PROGRESS = 'COLLECTION_NFTS_FETCH_IN_PROGRESS';
export const COLLECTION_NFTS_FETCH_ERROR = 'COLLECTION_NFTS_FETCH_ERROR';

export const TYPES_FETCH_SUCCESS = 'TYPES_FETCH_SUCCESS';
export const TYPES_FETCH_IN_PROGRESS = 'TYPES_FETCH_IN_PROGRESS';
export const TYPES_FETCH_ERROR = 'TYPES_FETCH_ERROR';

export const CLAIMABLE_TICKET_SET = 'CLAIMABLE_TICKET_SET';
export const CLAIMABLE_TICKETS_FETCH_IN_PROGRESS = 'CLAIMABLE_TICKETS_FETCH_IN_PROGRESS';
export const CLAIMABLE_TICKETS_FETCH_SUCCESS = 'CLAIMABLE_TICKETS_FETCH_SUCCESS';
export const CLAIMABLE_TICKETS_FETCH_ERROR = 'CLAIMABLE_TICKETS_FETCH_ERROR';

export const CLAIM_TICKET_DROP_IN_PROGRESS = 'CLAIM_TICKET_DROP_IN_PROGRESS';
export const CLAIM_TICKET_DROP_SUCCESS = 'CLAIM_TICKET_DROP_SUCCESS';
export const CLAIM_TICKET_DROP_ERROR = 'CLAIM_TICKET_DROP_ERROR';
export const TRANSFER_MY_TICKET_DIALOG_SHOW = 'TRANSFER_MY_TICKET_DIALOG_SHOW';
export const TRANSFER_MY_TICKET_DIALOG_HIDE = 'TRANSFER_MY_TICKET_DIALOG_HIDE';

export const TRANSFER_MY_TICKET_IN_PROGRESS = 'TRANSFER_MY_TICKET_IN_PROGRESS';
export const TRANSFER_MY_TICKET_SUCCESS = 'TRANSFER_MY_TICKET_SUCCESS';
export const TRANSFER_MY_TICKET_ERROR = 'TRANSFER_MY_TICKET_ERROR';

export const ALL_DROPS_FETCH_SUCCESS = 'ALL_DROPS_FETCH_SUCCESS';
export const ALL_DROPS_FETCH_IN_PROGRESS = 'ALL_DROPS_FETCH_IN_PROGRESS';
export const ALL_DROPS_FETCH_ERROR = 'ALL_DROPS_FETCH_ERROR';

export const CONFIRM_SWITCH_DIALOG_SHOW = 'CONFIRM_SWITCH_DIALOG_SHOW';
export const CONFIRM_SWITCH_DIALOG_HIDE = 'CONFIRM_SWITCH_DIALOG_HIDE';

export const NFTS_TAB_VALUE_SET = 'NFTS_TAB_VALUE_SET';
