import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import backIcon from '../../../assets/chevron-left.svg';
import ProjectNameTextField from './ProjectNameTextField';
import ProjectTwitterTextField from './ProjectTwitter';
import ShortDescriptionTextField from './ShortDescriptionTextField';
import LongDescriptionTextField from './LongDescriptionTextField';
import CompanyNameTextField from './CompanyNameTextField';
import EmailAddressTextField from './EmailAddressTextField';
import ContactPersonName from './ContactPersonName';
import ContactPersonTwitter from './ContactPersonTwitter';
import ContactPersonEmail from './ContactPersonEmail';
import WebsiteURLTextField from './WebsiteURlTextField';
import SponsorshipType from './SponsorshipType';
import CategorySelectField from './CategorySelectField';
import { Button } from '@mui/material';

class SponsorsRegistration extends React.Component {
    render () {
        return (
            <div className="sponsors_registration">
                <div className="header">
                    <img alt="back" src={backIcon}/>
                    <p>{variables[this.props.lang].sponsor_registration}</p>
                </div>
                <div className="fields_section">
                    <ProjectNameTextField/>
                    <ProjectTwitterTextField/>
                    <ShortDescriptionTextField/>
                    <LongDescriptionTextField/>
                    <CompanyNameTextField/>
                    <EmailAddressTextField/>
                    <ContactPersonName/>
                    <ContactPersonEmail/>
                    <WebsiteURLTextField/>
                    <ContactPersonTwitter/>
                    <SponsorshipType/>
                    <CategorySelectField/>
                </div>
                <div className="upload_logo">
                    <p>{variables[this.props.lang].upload_logo}</p>
                    <div className="drag_drop">
                        <p>{variables[this.props.lang].drag_drop}</p>
                        <Button>
                            {variables[this.props.lang].add_files}
                        </Button>
                    </div>
                </div>
                <div className="actions">
                    <Button>
                        {variables[this.props.lang].submit}
                    </Button>
                </div>
            </div>
        );
    }
}

SponsorsRegistration.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

const actionsToProps = {};

export default connect(stateToProps, actionsToProps)(SponsorsRegistration);
