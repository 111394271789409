import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import { Button, Dialog, IconButton } from '@mui/material';
import variables from '../../../utils/variables';
import { hideEligibilityCheckDialog } from '../../../actions/buyTickets';
import withRouter from '../../../components/WithRouter';
import { showMessage } from '../../../actions/snackbar';
import { ReactComponent as CloseIcon } from '../../../assets/tickets/x-close.svg';
import loyaltyTicket from '../../../assets/tickets/loyalty.png';

const EligibilityCheckDialog = (props) => {
    const handleClick = () => {
        props.router.navigate('/tickets/ticketType/' + (props.id));
        props.handleClose();
    };

    return (
        <Dialog
            aria-describedby="eligibility status"
            aria-labelledby="Eligibility check"
            className="dialog eligibility_dialog"
            open={props.open}
            onClose={props.handleClose}>
            <div className="connect_dialog_header">
                <h2 className="title">{variables[props.lang]['eligibility_check']}</h2>
                <IconButton onClick={props.handleClose}>
                    <CloseIcon/>
                </IconButton>
            </div>
            <div className="connect_dialog_content">
                <div className="spinner">
                    <img alt="animation" src={loyaltyTicket}/>
                </div>
                {props.success
                    ? <p className="success">{variables[props.lang]['eligible_loyalty_pass']}</p>
                    : <p className="failed">{variables[props.lang]['not_eligible_loyalty_pass']}</p>}
                {props.success
                    ? null
                    : <div className="not_eligible">
                        <span>{variables[props.lang]['not_eligible_loyalty_pass1']}</span>
                        <span>{variables[props.lang]['not_eligible_loyalty_pass2']}</span>
                    </div>}
                {props.success
                    ? <Button className="buy_now" onClick={handleClick}>
                        Buy Tickets
                    </Button>
                    : <Button className="buy_now" onClick={props.handleClose}>
                        Explore other Tickets
                    </Button>}
            </div>
        </Dialog>
    );
};

EligibilityCheckDialog.propTypes = {
    data: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }).isRequired,
    showMessage: PropTypes.func.isRequired,
    id: PropTypes.string,
    success: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.buyTickets.eligibilityCheckDialog.open,
        id: state.buyTickets.eligibilityCheckDialog.id,
        success: state.buyTickets.eligibilityCheckDialog.success,
        data: state.buyTickets.eligibilityCheckDialog.data,
    };
};

const actionToProps = {
    showMessage: showMessage,
    handleClose: hideEligibilityCheckDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(EligibilityCheckDialog));
