import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setIndustryCategory } from '../../../actions/ads';
import { MenuItem, Select } from '@mui/material';

const CategorySelectField = (props) => {
    const handleChange = (event) => {
        props.onChange(event.target.value);
    };

    return (
        <Select
            className="select_field text_field"
            id="demo-select-small"
            value={props.value}
            onChange={handleChange}>
            <MenuItem value="">
                <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
        </Select>
    );
};

CategorySelectField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.ads.industryCategory.value,
    };
};

const actionsToProps = {
    onChange: setIndustryCategory,
};

export default connect(stateToProps, actionsToProps)(CategorySelectField);
