import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { hideSnackbar } from '../actions/snackbar';
import Snackbar from '../components/Snackbar';
import withRouter from '../components/WithRouter';
import { fetchAccessToken } from '../actions/signIn';

class SnackbarMessage extends Component {
    componentDidUpdate (pp, ps, ss) {
        if (pp.message !== this.props.message) {
            switch (this.props.message) {
            case 'Token is expired':
            case 'Error occurred while verifying the JWT token.':
            case 'User Id and token combination does not exist.':
                this.props.onClose();

                /* use with Access Token API */
                if (this.props.tokenInProgress) {
                    return;
                }

                return this.props.fetchAccessToken((error) => {
                    if (error) {
                        const location = this.props.router && this.props.router.location && this.props.router.location.pathname;
                        localStorage.clear();
                        if (location && location.includes('ticketType')) {
                            return;
                        }
                        this.props.router.navigate('/');
                    }
                });
            default:
                break;
            }
        }
    }

    render () {
        return (
            <Snackbar
                message={this.props.message}
                open={this.props.open}
                onClose={this.props.onClose}/>
        );
    }
}

SnackbarMessage.propTypes = {
    fetchAccessToken: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    tokenInProgress: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    router: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired,
        }).isRequired,
        navigate: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        open: state.snackbar.open,
        message: state.snackbar.message,
        tokenInProgress: state.signIn.token.inProgress,
    };
};

const actionsToProps = {
    fetchAccessToken,
    onClose: hideSnackbar,
};

export default withRouter(connect(stateToProps, actionsToProps)(SnackbarMessage));
