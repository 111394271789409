import React from 'react';
import * as PropTypes from 'prop-types';
import flixToken from '../../assets/tokens/flix.svg';
import atomToken from '../../assets/tokens/atom.svg';

const NetworkImages = (props) => {
    let imageIcon = null;
    switch (props.name) {
    case 'ATOM':
    case 'uatom':
        imageIcon = atomToken;
        break;
    case 'FLIX':
    case 'uflix':
        imageIcon = flixToken;
        break;
    case 'usd':
        return '$';
    case 'inr':
        return '₹';
    default:
        break;
    }

    return props.name !== '' && imageIcon && <img alt={props.alt ? props.alt : 'icon'} src={imageIcon}/>;
};

NetworkImages.propTypes = {
    alt: PropTypes.string,
    name: PropTypes.string,
};

export default NetworkImages;
