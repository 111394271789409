import {
    BUY_TICKETS_EXPANSION_SET,
    CLEAR_CREATE_ORDER,
    CONFIRM_DIALOG_HIDE,
    CONFIRM_DIALOG_SHOW,
    CONFIRM_DIALOG_SUCCESS_SET,
    CONNECT_DIALOG_HIDE,
    CONNECT_DIALOG_SHOW,
    COUPON_CODE_DATA_FETCH_ERROR,
    COUPON_CODE_DATA_FETCH_IN_PROGRESS,
    COUPON_CODE_DATA_FETCH_SUCCESS,
    COUPON_CODE_REMOVE,
    COUPON_CODE_SET,
    CREATE_ORDER_ERROR,
    CREATE_ORDER_IN_PROGRESS,
    CREATE_ORDER_SUCCESS,
    ELIGIBILITY_CHECK_DIALOG_HIDE,
    ELIGIBILITY_CHECK_DIALOG_SHOW,
    ELIGIBILITY_CHECK_FETCH_ERROR,
    ELIGIBILITY_CHECK_FETCH_IN_PROGRESS,
    ELIGIBILITY_CHECK_FETCH_SUCCESS,
    ELIGIBILITY_CHECK_LOGIN_SET,
    EMAIL_ADDRESS_SET,
    FETCH_TICKET_TYPE_ERROR,
    FETCH_TICKET_TYPE_IN_PROGRESS,
    FETCH_TICKET_TYPE_SUCCESS,
    FIRST_NAME_SET,
    FULL_NAME_SET,
    LAST_NAME_SET,
    MY_ORDER_FETCH_ERROR,
    MY_ORDER_FETCH_IN_PROGRESS,
    MY_ORDER_FETCH_SUCCESS,
    NO_OF_TICKETS_SET,
    OTP_VALUE_SET,
    OTP_VERIFY_SET,
    PAYMENT_METHOD_SET,
    STRIP_SECRET_FETCH_ERROR,
    STRIP_SECRET_FETCH_IN_PROGRESS,
    STRIP_SECRET_FETCH_SUCCESS,
    TICKETS_DATA_SET,
    TIME_OUT_DIALOG_HIDE,
    TIME_OUT_DIALOG_SHOW,
} from '../constants/buyTickets';
import { combineReducers } from 'redux';
import { DISCONNECT_SET } from '../constants/wallet';
import { PROFILE_DETAILS_FETCH_SUCCESS } from '../constants/myAccount';

const noOfTickets = (state = {
    count: 1,
    data: {},
    valid: true,
}, action) => {
    switch (action.type) {
    case NO_OF_TICKETS_SET:
        return {
            ...state,
            count: action.value,
        };
    case TICKETS_DATA_SET:
        return {
            ...state,
            data: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_ORDER:
        return {
            count: 1,
            data: {},
            valid: true,
        };
    default:
        return state;
    }
};

const couponCode = (state = {
    value: '',
    inProgress: false,
    data: {},
}, action) => {
    switch (action.type) {
    case COUPON_CODE_SET:
        return {
            ...state,
            value: action.value,
        };
    case COUPON_CODE_DATA_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COUPON_CODE_DATA_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            data: action.value,
        };
    case COUPON_CODE_DATA_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_CREATE_ORDER:
    case COUPON_CODE_REMOVE:
        return {
            ...state,
            value: '',
            data: {},
        };
    default:
        return state;
    }
};

const ticketsExpansion = (state = 'info', action) => {
    switch (action.type) {
    case BUY_TICKETS_EXPANSION_SET:
        return action.value;
    case CLEAR_CREATE_ORDER:
        return 'info';

    default:
        return state;
    }
};

const fullName = (state = '', action) => {
    switch (action.type) {
    case FULL_NAME_SET:
        return action.value;
    default:
        return state;
    }
};

const firstName = (state = '', action) => {
    switch (action.type) {
    case FIRST_NAME_SET:
        return action.value;
    default:
        return state;
    }
};

const lastName = (state = '', action) => {
    switch (action.type) {
    case LAST_NAME_SET:
        return action.value;
    default:
        return state;
    }
};

const emailAddress = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case EMAIL_ADDRESS_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.email_address ? action.value.email_address : '',
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const otp = (state = '', action) => {
    switch (action.type) {
    case OTP_VALUE_SET:
        return action.value;

    default:
        return state;
    }
};

const otpVerify = (state = false, action) => {
    switch (action.type) {
    case OTP_VERIFY_SET:
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return true;
    case DISCONNECT_SET:
        return false;

    default:
        return state;
    }
};

const paymentMethod = (state = 'stripe', action) => {
    switch (action.type) {
    case PAYMENT_METHOD_SET:
        return action.value;

    default:
        return state;
    }
};

const confirmDialog = (state = {
    open: false,
    success: false,
}, action) => {
    switch (action.type) {
    case CONFIRM_DIALOG_SHOW:
        return {
            ...state,
            open: true,
        };
    case CONFIRM_DIALOG_HIDE:
        return {
            open: false,
            success: false,
        };
    case CONFIRM_DIALOG_SUCCESS_SET:
        return {
            ...state,
            success: action.value,
        };
    default:
        return state;
    }
};

const order = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CREATE_ORDER_IN_PROGRESS:
    case MY_ORDER_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CREATE_ORDER_SUCCESS:
    case MY_ORDER_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case CREATE_ORDER_ERROR:
    case MY_ORDER_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_CREATE_ORDER:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const ticketType = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case FETCH_TICKET_TYPE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_TICKET_TYPE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case FETCH_TICKET_TYPE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const timeOutDialog = (state = false, action) => {
    switch (action.type) {
    case TIME_OUT_DIALOG_SHOW:
        return true;
    case TIME_OUT_DIALOG_HIDE:
        return false;

    default:
        return state;
    }
};

const stripeSecret = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case STRIP_SECRET_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case STRIP_SECRET_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case STRIP_SECRET_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const connectDialog = (state = false, action) => {
    switch (action.type) {
    case CONNECT_DIALOG_SHOW:
        return true;
    case CONNECT_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const eligibilityCheckDialog = (state = {
    open: false,
    success: false,
    id: null,
    loginDialog: false,
}, action) => {
    switch (action.type) {
    case ELIGIBILITY_CHECK_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            success: action.value,
            id: action.id,
        };
    case ELIGIBILITY_CHECK_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            success: false,
            id: null,
            loginDialog: false,
        };
    case ELIGIBILITY_CHECK_LOGIN_SET:
        return {
            ...state,
            loginDialog: action.value,
            id: action.id,
        };
    default:
        return state;
    }
};

const eligibilityCheck = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ELIGIBILITY_CHECK_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ELIGIBILITY_CHECK_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case ELIGIBILITY_CHECK_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    noOfTickets,
    couponCode,
    ticketsExpansion,
    fullName,
    emailAddress,
    otp,
    otpVerify,
    paymentMethod,
    confirmDialog,
    order,
    ticketType,
    timeOutDialog,
    stripeSecret,
    connectDialog,
    firstName,
    lastName,
    eligibilityCheckDialog,
    eligibilityCheck,
});
