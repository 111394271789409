import {
    ALL_DROPS_FETCH_ERROR,
    ALL_DROPS_FETCH_IN_PROGRESS,
    ALL_DROPS_FETCH_SUCCESS,
    BC_CONNECT_DIALOG_HIDE,
    BC_CONNECT_DIALOG_SHOW,
    CLAIM_ADDRESS_SET,
    CLAIM_DIALOG_HIDE,
    CLAIM_DIALOG_SHOW,
    CLAIM_NFT_ERROR,
    CLAIM_NFT_IN_PROGRESS,
    CLAIM_NFT_SUCCESS,
    CLAIM_STATUS_FETCH_ERROR,
    CLAIM_STATUS_FETCH_IN_PROGRESS,
    CLAIM_STATUS_FETCH_SUCCESS,
    CLAIM_TICKET_DROP_ERROR,
    CLAIM_TICKET_DROP_IN_PROGRESS,
    CLAIM_TICKET_DROP_SUCCESS,
    CLAIMABLE_TICKET_SET,
    CLAIMABLE_TICKETS_FETCH_ERROR,
    CLAIMABLE_TICKETS_FETCH_IN_PROGRESS,
    CLAIMABLE_TICKETS_FETCH_SUCCESS,
    CLAIMED_LIST_FETCH_ERROR,
    CLAIMED_LIST_FETCH_IN_PROGRESS,
    CLAIMED_LIST_FETCH_SUCCESS,
    COLLECTION_NFTS_FETCH_ERROR,
    COLLECTION_NFTS_FETCH_IN_PROGRESS,
    COLLECTION_NFTS_FETCH_SUCCESS,
    CONFIRM_SWITCH_DIALOG_HIDE,
    CONFIRM_SWITCH_DIALOG_SHOW,
    DROPS_TAB_SET,
    ELIGIBLE_CLAIM_DIALOG_HIDE,
    ELIGIBLE_CLAIM_DIALOG_SHOW,
    ELIGIBLE_CLAIM_NFT_SET,
    ELIGIBLE_CLAIM_TYPE_SET,
    ELIGIBLE_LIST_FETCH_ERROR,
    ELIGIBLE_LIST_FETCH_IN_PROGRESS,
    ELIGIBLE_LIST_FETCH_SUCCESS,
    FETCH_PROFILE_IMAGE_ERROR,
    FETCH_PROFILE_IMAGE_IN_PROGRESS,
    FETCH_PROFILE_IMAGE_SUCCESS,
    INVOICE_DOWNLOAD_ERROR,
    INVOICE_DOWNLOAD_IN_PROGRESS,
    INVOICE_DOWNLOAD_SUCCESS,
    NFT_S_FETCH_ERROR,
    NFT_S_FETCH_IN_PROGRESS,
    NFT_S_FETCH_SUCCESS,
    NFTS_TAB_VALUE_SET,
    ORDERS_FETCH_ERROR,
    ORDERS_FETCH_IN_PROGRESS,
    ORDERS_FETCH_SUCCESS,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    QR_CODE_FETCH_ERROR,
    QR_CODE_FETCH_IN_PROGRESS,
    QR_CODE_FETCH_SUCCESS,
    SAME_BC_CONNECT_ERROR_SET,
    SAME_BC_CONNECT_ERROR_SET_FALSE,
    STATS_FETCH_ERROR,
    STATS_FETCH_IN_PROGRESS,
    STATS_FETCH_SUCCESS,
    TAB_VALUE_SET,
    TICKET_CANCEL_DIALOG_HIDE,
    TICKET_CANCEL_DIALOG_SHOW,
    TICKET_CANCEL_ERROR,
    TICKET_CANCEL_IN_PROGRESS,
    TICKET_CANCEL_SUCCESS,
    TICKET_DOWNLOAD_ERROR,
    TICKET_DOWNLOAD_IN_PROGRESS,
    TICKET_DOWNLOAD_SUCCESS,
    TICKET_FETCH_ERROR,
    TICKET_FETCH_IN_PROGRESS,
    TICKET_FETCH_SUCCESS,
    TICKETS_FETCH_ERROR,
    TICKETS_FETCH_IN_PROGRESS,
    TICKETS_FETCH_SUCCESS,
    TRANSFER_MY_TICKET_DIALOG_HIDE,
    TRANSFER_MY_TICKET_DIALOG_SHOW,
    TRANSFER_MY_TICKET_ERROR,
    TRANSFER_MY_TICKET_IN_PROGRESS,
    TRANSFER_MY_TICKET_SUCCESS,
    TYPES_FETCH_ERROR,
    TYPES_FETCH_IN_PROGRESS,
    TYPES_FETCH_SUCCESS,
    UPDATE_NAME_SET,
    UPDATE_NAME_VALUE_SET,
    UPDATE_PROFILE_ERROR,
    UPDATE_PROFILE_IMAGE_ERROR,
    UPDATE_PROFILE_IMAGE_IN_PROGRESS,
    UPDATE_PROFILE_IMAGE_SUCCESS,
    UPDATE_PROFILE_IN_PROGRESS,
    UPDATE_PROFILE_SUCCESS,
} from '../constants/myAccount';
import {
    PROFILE_DETAILS_FETCH_URL,
    PROFILE_IMAGE_FETCH_URL,
    STATS_FETCH_URL,
    urlAllDrops,
    urlCancelTicket,
    urlClaimNFT,
    urlClaimStatus,
    urlClaimTicketDrop,
    urlCollectionNfts,
    urlDownloadTicket,
    urlFetchClaimableTickets,
    urlFetchClaimed,
    urlFetchEligible,
    urlFetchEligibleTypes,
    urlFetchOrders,
    urlFetchQRCode,
    urlFetchTicket,
    urlFetchTickets,
    urlInvoiceTicket,
    urlTransferTicket,
} from '../constants/url';
import Axios from 'axios';
import fileDownload from 'js-file-download';

export const setTabValue = (value) => {
    return {
        type: TAB_VALUE_SET,
        value,
    };
};

export const setUpdateName = (value) => {
    return {
        type: UPDATE_NAME_SET,
        value,
    };
};

export const setUpdateNameValue = (value) => {
    return {
        type: UPDATE_NAME_VALUE_SET,
        value,
    };
};

export const showClaimDialog = () => {
    return {
        type: CLAIM_DIALOG_SHOW,
    };
};

export const hideClaimDialog = () => {
    return {
        type: CLAIM_DIALOG_HIDE,
    };
};

export const setClaimAddress = (value) => {
    return {
        type: CLAIM_ADDRESS_SET,
        value,
    };
};

export const showTransferMyTicketDialog = (value) => {
    return {
        type: TRANSFER_MY_TICKET_DIALOG_SHOW,
        value,
    };
};

export const hideTransferMyTicketDialog = () => {
    return {
        type: TRANSFER_MY_TICKET_DIALOG_HIDE,
    };
};

export const showEligibleClaimDialog = (value) => {
    return {
        type: ELIGIBLE_CLAIM_DIALOG_SHOW,
        value,
    };
};

export const hideEligibleClaimDialog = () => {
    return {
        type: ELIGIBLE_CLAIM_DIALOG_HIDE,
    };
};

export const setEligibleClaimType = (value) => {
    return {
        type: ELIGIBLE_CLAIM_TYPE_SET,
        value,
    };
};

export const setEligibleClaimNft = (value) => {
    return {
        type: ELIGIBLE_CLAIM_NFT_SET,
        value,
    };
};

export const setDropsTabValue = (value) => {
    return {
        type: DROPS_TAB_SET,
        value,
    };
};

export const setNFTsTabValue = (value) => {
    return {
        type: NFTS_TAB_VALUE_SET,
        value,
    };
};

const fetchProfileDetailsInProgress = () => {
    return {
        type: PROFILE_DETAILS_FETCH_IN_PROGRESS,
    };
};

const fetchProfileDetailsSuccess = (value) => {
    return {
        type: PROFILE_DETAILS_FETCH_SUCCESS,
        value,
    };
};

const fetchProfileDetailsError = (message) => {
    return {
        type: PROFILE_DETAILS_FETCH_ERROR,
        message,
    };
};

export const fetchProfileDetails = (cb) => (dispatch) => {
    dispatch(fetchProfileDetailsInProgress());

    Axios.get(PROFILE_DETAILS_FETCH_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchProfileDetailsSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchProfileDetailsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchOrdersInProgress = () => {
    return {
        type: ORDERS_FETCH_IN_PROGRESS,
    };
};

const fetchOrdersSuccess = (value, skip, limit, total, sortBy, order) => {
    return {
        type: ORDERS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
    };
};

const fetchOrdersError = (message) => {
    return {
        type: ORDERS_FETCH_ERROR,
        message,
    };
};

export const fetchOrders = (skip, limit, cb) => (dispatch) => {
    dispatch(fetchOrdersInProgress());

    const url = urlFetchOrders(skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchOrdersSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list);
            }
        })
        .catch((error) => {
            dispatch(fetchOrdersError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchTicketsInProgress = () => {
    return {
        type: TICKETS_FETCH_IN_PROGRESS,
    };
};

const fetchTicketsSuccess = (value, skip, limit, total) => {
    return {
        type: TICKETS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
    };
};

const fetchTicketsError = (message) => {
    return {
        type: TICKETS_FETCH_ERROR,
        message,
    };
};

export const fetchTickets = (skip, limit, cb) => (dispatch) => {
    dispatch(fetchTicketsInProgress());

    const url = urlFetchTickets(skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchTicketsSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list);
            }
        })
        .catch((error) => {
            dispatch(fetchTicketsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchTicketInProgress = () => {
    return {
        type: TICKET_FETCH_IN_PROGRESS,
    };
};

const fetchTicketSuccess = (value) => {
    return {
        type: TICKET_FETCH_SUCCESS,
        value,
    };
};

const fetchTicketError = (message) => {
    return {
        type: TICKET_FETCH_ERROR,
        message,
    };
};

export const fetchTicket = (id, cb) => (dispatch) => {
    dispatch(fetchTicketInProgress());

    const url = urlFetchTicket(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchTicketSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchTicketError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const downloadTicketsInProgress = (id) => {
    return {
        type: TICKET_DOWNLOAD_IN_PROGRESS,
        id,
    };
};

const downloadTicketsSuccess = (value, filename) => {
    return {
        type: TICKET_DOWNLOAD_SUCCESS,
        message: 'Downloaded',
        value,
        filename,
    };
};

const downloadTicketsError = (message) => {
    return {
        type: TICKET_DOWNLOAD_ERROR,
        message,
    };
};

export const downloadTickets = (ticketType, id, filename) => (dispatch) => {
    dispatch(downloadTicketsInProgress(id));

    const url = urlDownloadTicket(ticketType, id);
    Axios.get(url, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            fileDownload(res.data, filename, res.data && res.data.type);

            dispatch(downloadTicketsSuccess(res.data, filename));
        })
        .catch((error) => {
            dispatch(downloadTicketsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : error.response &&
                    error.response.message
                        ? error.response.message
                        : 'Failed!',
            ));
        });
};

export const showCancelTicketDialog = (id) => {
    return {
        type: TICKET_CANCEL_DIALOG_SHOW,
        id,
    };
};

export const hideCancelTicketDialog = () => {
    return {
        type: TICKET_CANCEL_DIALOG_HIDE,
    };
};

const cancelTicketInProgress = () => {
    return {
        type: TICKET_CANCEL_IN_PROGRESS,
    };
};

const cancelTicketSuccess = (value) => {
    return {
        type: TICKET_CANCEL_SUCCESS,
        value,
        message: 'Success',
    };
};

const cancelTicketError = (message) => {
    return {
        type: TICKET_CANCEL_ERROR,
        message,
    };
};

export const cancelTicket = (id, cb) => (dispatch) => {
    dispatch(cancelTicketInProgress());

    const url = urlCancelTicket(id);
    Axios.post(url, {}, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(cancelTicketSuccess(res.data && res.data.result));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(cancelTicketError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const updateProfileInProgress = () => {
    return {
        type: UPDATE_PROFILE_IN_PROGRESS,
    };
};

const updateProfileSuccess = (value, message) => {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateProfileError = (message) => {
    return {
        type: UPDATE_PROFILE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateProfile = (data, cb) => (dispatch) => {
    dispatch(updateProfileInProgress());

    Axios.put(PROFILE_DETAILS_FETCH_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(updateProfileSuccess(res.data && res.data.result, 'Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateProfileError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

const updateProfileImageInProgress = () => {
    return {
        type: UPDATE_PROFILE_IMAGE_IN_PROGRESS,
    };
};

const updateProfileImageSuccess = (value, message) => {
    return {
        type: UPDATE_PROFILE_IMAGE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateProfileImageError = (message) => {
    return {
        type: UPDATE_PROFILE_IMAGE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateProfileImage = (image) => (dispatch) => {
    dispatch(updateProfileImageInProgress());
    const body = new FormData();
    body.append('file', image);

    Axios.put(PROFILE_IMAGE_FETCH_URL + '?send=true', body, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            const profileImage = res.data;
            const file = new Blob([profileImage], { type: profileImage.type });
            const fileUrl = URL.createObjectURL(file);

            dispatch(updateProfileImageSuccess(fileUrl, 'Updated Successfully'));
        })
        .catch((error) => {
            dispatch(updateProfileImageError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchProfileImageInProgress = () => {
    return {
        type: FETCH_PROFILE_IMAGE_IN_PROGRESS,
    };
};

const fetchProfileImageSuccess = (value) => {
    return {
        type: FETCH_PROFILE_IMAGE_SUCCESS,
        value,
    };
};

const fetchProfileImageError = (message) => {
    return {
        type: FETCH_PROFILE_IMAGE_ERROR,
        message,
    };
};

export const fetchProfileImage = () => (dispatch) => {
    dispatch(fetchProfileImageInProgress());

    Axios.get(PROFILE_IMAGE_FETCH_URL, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            const profileImage = res.data;
            const file = new Blob([profileImage], { type: profileImage.type });
            const fileUrl = URL.createObjectURL(file);

            dispatch(fetchProfileImageSuccess(fileUrl));
        })
        .catch((error) => {
            dispatch(fetchProfileImageError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchStatsInProgress = () => {
    return {
        type: STATS_FETCH_IN_PROGRESS,
    };
};

const fetchStatsSuccess = (value) => {
    return {
        type: STATS_FETCH_SUCCESS,
        value,
    };
};

const fetchStatsError = (message) => {
    return {
        type: STATS_FETCH_ERROR,
        message,
    };
};

export const fetchStats = () => (dispatch) => {
    dispatch(fetchStatsInProgress());

    Axios.get(STATS_FETCH_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchStatsSuccess(res && res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchQRCodeInProgress = () => {
    return {
        type: QR_CODE_FETCH_IN_PROGRESS,
    };
};

const fetchQRCodeSuccess = (value) => {
    return {
        type: QR_CODE_FETCH_SUCCESS,
        value,
    };
};

const fetchQRCodeError = (message) => {
    return {
        type: QR_CODE_FETCH_ERROR,
        message,
    };
};

export const fetchQRCode = (id, ticketID) => (dispatch) => {
    dispatch(fetchQRCodeInProgress());

    const url = urlFetchQRCode(ticketID);
    Axios.get(url, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            const qrCode = res.data;
            const file = new Blob([qrCode], { type: qrCode.type });
            const fileUrl = URL.createObjectURL(file);
            dispatch(fetchQRCodeSuccess({
                image: fileUrl,
                id: id,
            }));
        })
        .catch((error) => {
            dispatch(fetchQRCodeError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchClaimStatusInProgress = () => {
    return {
        type: CLAIM_STATUS_FETCH_IN_PROGRESS,
    };
};

const fetchClaimStatusSuccess = (id, value) => {
    return {
        type: CLAIM_STATUS_FETCH_SUCCESS,
        id,
        value,
    };
};

const fetchClaimStatusError = (message) => {
    return {
        type: CLAIM_STATUS_FETCH_ERROR,
        message,
    };
};

export const fetchClaimStatus = (id, ticketID) => (dispatch) => {
    dispatch(fetchClaimStatusInProgress());

    const url = urlClaimStatus(ticketID);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchClaimStatusSuccess(id, res && res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchClaimStatusError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const claimNFTInProgress = () => {
    return {
        type: CLAIM_NFT_IN_PROGRESS,
    };
};

const claimNFTSuccess = (value) => {
    return {
        type: CLAIM_NFT_SUCCESS,
        value,
    };
};

const claimNFTError = (message) => {
    return {
        type: CLAIM_NFT_ERROR,
        message,
    };
};

export const claimNFT = (data, ticketID, cb) => (dispatch) => {
    dispatch(claimNFTInProgress());

    const url = urlClaimNFT(ticketID);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(claimNFTSuccess(res && res.data));
            cb(res && res.data && res.data.success);
        })
        .catch((error) => {
            dispatch(claimNFTError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchNFTsInProgress = () => {
    return {
        type: NFT_S_FETCH_IN_PROGRESS,
    };
};

const fetchNFTsSuccess = (value, skip, limit, total) => {
    return {
        type: NFT_S_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
    };
};

const fetchNFTsError = (message) => {
    return {
        type: NFT_S_FETCH_ERROR,
        message,
    };
};

export const fetchNFTs = (skip, limit, cb) => (dispatch) => {
    dispatch(fetchNFTsInProgress());

    const url = urlFetchTickets(skip, limit, true);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchNFTsSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list);
            }
        })
        .catch((error) => {
            dispatch(fetchNFTsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const downloadInvoiceInProgress = (id) => {
    return {
        type: INVOICE_DOWNLOAD_IN_PROGRESS,
        id,
    };
};

const downloadInvoiceSuccess = (value, filename) => {
    return {
        type: INVOICE_DOWNLOAD_SUCCESS,
        message: 'Downloaded',
        value,
        filename,
    };
};

const downloadInvoiceError = (message) => {
    return {
        type: INVOICE_DOWNLOAD_ERROR,
        message,
    };
};

export const downloadInvoice = (id, filename) => (dispatch) => {
    dispatch(downloadInvoiceInProgress(id));

    const url = urlInvoiceTicket(id);
    Axios.get(url, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            fileDownload(res.data, filename, res.data && res.data.type);

            dispatch(downloadInvoiceSuccess(res.data, filename));
        })
        .catch((error) => {
            dispatch(downloadInvoiceError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : error.response &&
                    error.response.message
                        ? error.response.message
                        : 'Failed!',
            ));
        });
};

export const showBcConnectDialog = (showTooltip) => {
    return {
        type: BC_CONNECT_DIALOG_SHOW,
        showTooltip,
    };
};

export const hideBcConnectDialog = () => {
    return {
        type: BC_CONNECT_DIALOG_HIDE,
    };
};

export const setSameBcConnectError = () => {
    return {
        type: SAME_BC_CONNECT_ERROR_SET,
    };
};

export const setSameBcConnectErrorFalse = () => {
    return {
        type: SAME_BC_CONNECT_ERROR_SET_FALSE,
    };
};

export const showConfirmSwitchDialog = (value) => {
    return {
        type: CONFIRM_SWITCH_DIALOG_SHOW,
        value,
    };
};

export const hideConfirmSwitchDialog = () => {
    return {
        type: CONFIRM_SWITCH_DIALOG_HIDE,
    };
};

export const transferMyTicketInProgress = () => {
    return {
        type: TRANSFER_MY_TICKET_IN_PROGRESS,
    };
};

export const transferMyTicketSuccess = (value, message) => {
    return {
        type: TRANSFER_MY_TICKET_SUCCESS,
        value,
        message,
    };
};

export const transferMyTicketError = (message) => {
    return {
        type: TRANSFER_MY_TICKET_ERROR,
        message,
        variant: 'error',
    };
};

export const transferMyTicket = (data, id, cb) => (dispatch) => {
    dispatch(transferMyTicketInProgress());

    const url = urlTransferTicket(id);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(transferMyTicketSuccess(res.data && res.data.result, 'Transfered Successfully'));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(transferMyTicketError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchEligibleListInProgress = () => {
    return {
        type: ELIGIBLE_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchEligibleListSuccess = (value, skip, limit, total) => {
    return {
        type: ELIGIBLE_LIST_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
    };
};

const fetchEligibleListError = (message) => {
    return {
        type: ELIGIBLE_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchEligibleList = (skip, limit, cb) => (dispatch) => {
    dispatch(fetchEligibleListInProgress());

    const url = urlFetchEligible(skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchEligibleListSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list);
            }
        })
        .catch((error) => {
            dispatch(fetchEligibleListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchClaimedListInProgress = () => {
    return {
        type: CLAIMED_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchClaimedListSuccess = (value, skip, limit, total) => {
    return {
        type: CLAIMED_LIST_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
    };
};

const fetchClaimedListError = (message) => {
    return {
        type: CLAIMED_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchClaimedList = (skip, limit, cb) => (dispatch) => {
    dispatch(fetchClaimedListInProgress());

    const url = urlFetchClaimed(skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchClaimedListSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list);
            }
        })
        .catch((error) => {
            dispatch(fetchClaimedListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchCollectionNFTsInProgress = () => {
    return {
        type: COLLECTION_NFTS_FETCH_IN_PROGRESS,
    };
};

const fetchCollectionNFTsSuccess = (value, skip, limit, total, search) => {
    return {
        type: COLLECTION_NFTS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
        search,
    };
};

const fetchCollectionNFTsError = (message) => {
    return {
        type: COLLECTION_NFTS_FETCH_ERROR,
        message,
    };
};

export const fetchCollectionNFTs = (id, address, skip, limit, search, cb) => (dispatch) => {
    dispatch(fetchCollectionNFTsInProgress());

    const url = urlCollectionNfts(id, address, skip, limit, search);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchCollectionNFTsSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res && res.data && res.data.result && res.data.result.count, search));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list, res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            dispatch(fetchCollectionNFTsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchTypesInProgress = () => {
    return {
        type: TYPES_FETCH_IN_PROGRESS,
    };
};

const fetchTypesSuccess = (value, skip, limit, total, search) => {
    return {
        type: TYPES_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
        search,
    };
};

const fetchTypesError = (message) => {
    return {
        type: TYPES_FETCH_ERROR,
        message,
    };
};

export const fetchTypes = (types, skip, limit, cb) => (dispatch) => {
    dispatch(fetchTypesInProgress());

    const url = urlFetchEligibleTypes(types, skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchTypesSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res && res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list, res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            dispatch(fetchTypesError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

export const setClaimableTicket = (value) => {
    return {
        type: CLAIMABLE_TICKET_SET,
        value,
    };
};

const fetchClaimableTicketsInProgress = () => {
    return {
        type: CLAIMABLE_TICKETS_FETCH_IN_PROGRESS,
    };
};

const fetchClaimableTicketsSuccess = (value, skip, limit, total, search) => {
    return {
        type: CLAIMABLE_TICKETS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
        search,
    };
};

const fetchClaimableTicketsError = (message) => {
    return {
        type: CLAIMABLE_TICKETS_FETCH_ERROR,
        message,
    };
};

export const fetchClaimableTickets = (skip, limit, cb) => (dispatch) => {
    dispatch(fetchClaimableTicketsInProgress());

    const url = urlFetchClaimableTickets(skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchClaimableTicketsSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res && res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list, res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            dispatch(fetchClaimableTicketsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const claimTicketDropInProgress = () => {
    return {
        type: CLAIM_TICKET_DROP_IN_PROGRESS,
    };
};

const claimTicketDropSuccess = (value) => {
    return {
        type: CLAIM_TICKET_DROP_SUCCESS,
        value,
        message: 'Success',
    };
};

const claimTicketDropError = (message) => {
    return {
        type: CLAIM_TICKET_DROP_ERROR,
        message,
    };
};

export const claimTicketDrop = (data, id, cb) => (dispatch) => {
    dispatch(claimTicketDropInProgress());

    const url = urlClaimTicketDrop(id);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(claimTicketDropSuccess(res.data && res.data.result), 'Success');
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(claimTicketDropError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchAllDropsInProgress = () => {
    return {
        type: ALL_DROPS_FETCH_IN_PROGRESS,
    };
};

const fetchAllDropsSuccess = (value, skip, limit, total, search) => {
    return {
        type: ALL_DROPS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
        search,
    };
};

const fetchAllDropsError = (message) => {
    return {
        type: ALL_DROPS_FETCH_ERROR,
        message,
    };
};

export const fetchAllDrops = (skip, limit, address, cb) => (dispatch) => {
    dispatch(fetchAllDropsInProgress());

    const url = urlAllDrops(skip, limit, address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchAllDropsSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res && res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list, res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            dispatch(fetchAllDropsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};
