export const ENTRIES_LIST_FETCH_SUCCESS = 'ENTRIES_LIST_FETCH_SUCCESS';
export const ENTRIES_LIST_FETCH_IN_PROGRESS = 'ENTRIES_LIST_FETCH_IN_PROGRESS';
export const ENTRIES_LIST_FETCH_ERROR = 'ENTRIES_LIST_FETCH_ERROR';

export const CLAIM_POAP_DROP_SUCCESS = 'CLAIM_POAP_DROP_SUCCESS';
export const CLAIM_POAP_DROP_IN_PROGRESS = 'CLAIM_POAP_DROP_IN_PROGRESS';
export const CLAIM_POAP_DROP_ERROR = 'CLAIM_POAP_DROP_ERROR';

export const CLAIM_NFT_TAB_SET = 'CLAIM_NFT_TAB_SET';
export const SELECT_ENTRY_VALUE_SET = 'SELECT_ENTRY_VALUE_SET';

export const POAP_NFTS_LIST_FETCH_SUCCESS = 'POAP_NFTS_LIST_FETCH_SUCCESS';
export const POAP_NFTS_LIST_FETCH_IN_PROGRESS = 'POAP_NFTS_LIST_FETCH_IN_PROGRESS';
export const POAP_NFTS_LIST_FETCH_ERROR = 'POAP_NFTS_LIST_FETCH_ERROR';
