import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import EmailTextField from './EmailTextField';
import { Button, Dialog } from '@mui/material';
import OTPTextField from './OTPTextField';
import { hideSignInDialog, setEmail, signIn, signInVerify } from '../../actions/signIn';
import CircularProgress from '../../components/CircularProgress';
import withRouter from '../../components/WithRouter';
import { isValidEmail } from '../../utils/validation';
import Counter from './Counter';
import { fetchProfileDetails, fetchProfileImage, fetchStats, setTabValue } from '../../actions/myAccount';
import { fetchBalance } from '../../actions/account/BCDetails';
import { fetchEligibilityCheck, showEligibilityCheckDialog } from '../../actions/buyTickets';
import { showAdSlotConfirmDialog } from '../../actions/ads';

class SignIn extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            sendOTP: false,
        };

        this.handleOTP = this.handleOTP.bind(this);
        this.handleVerify = this.handleVerify.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSideClose = this.handleSideClose.bind(this);
        this.handleCheckEligibility = this.handleCheckEligibility.bind(this);
    }

    handleKeyPress (event) {
        if (this.state.sendOTP) {
            const otpDisabled = this.props.otp === '' || this.props.otp.length !== 6;

            if (event.key === 'Enter' && !otpDisabled) {
                this.handleVerify();
            }
        } else {
            if (event.key === 'Enter' && this.props.email !== '') {
                this.handleOTP();
            }
        }
    }

    handleOTP () {
        const valid = isValidEmail(this.props.email);
        this.props.setEmail(this.props.email, valid);

        const data = {
            emailAddress: this.props.email,
        };

        if (valid && this.props.email !== '') {
            this.props.signIn(data, (error) => {
                if (!error) {
                    this.setState({
                        sendOTP: true,
                    });
                }
            });
        }
    }

    handleVerify () {
        const data = {
            userId: this.props.signInData && this.props.signInData._id,
            otp: this.props.otp,
        };

        this.props.signInVerify(data, (error) => {
            if (!error) {
                this.props.fetchProfileDetails();
                this.props.fetchStats();
                this.props.fetchProfileImage();
                if (this.props.router && this.props.router.location && this.props.router.location.pathname &&
                    this.props.router.location.pathname === '/') {
                    if (this.props.eligibilityCheckLogin) {
                        this.handleCheckEligibility(this.props.ticketID);
                        this.handleClose();
                        return;
                    }

                    this.props.router.navigate('/');
                } else if (this.props.router && this.props.router.location && this.props.router.location.pathname &&
                    this.props.router.location.pathname === '/adx') {
                    this.props.router.navigate('/myAccount');
                    this.props.setTabValue('ads');
                    this.props.showAdSlotConfirmDialog();
                } else {
                    this.props.router.navigate('/myAccount');
                }

                this.handleClose();
            }
        });
    }

    handleCheckEligibility (id) {
        this.props.fetchEligibilityCheck(id, (result) => {
            if (result && result > 0) {
                this.props.showEligibilityCheckDialog(true, id);
                return;
            }

            this.props.showEligibilityCheckDialog(false, id);
        });
    }

    handleClose () {
        if (this.state.sendOTP) {
            this.setState({
                sendOTP: false,
            });
        }

        this.props.handleClose();
    }

    handleSideClose () {
        if (!this.state.sendOTP) {
            this.handleClose();
        }
    }

    render () {
        const otpDisabled = this.props.otp === '' || this.props.otp.length !== 6;

        return (
            <Dialog
                aria-describedby="sign-in-to-account"
                aria-labelledby="sign-in"
                className="dialog sign_in_dialog"
                open={this.props.open}
                onClose={this.handleSideClose}>
                {this.props.inProgress
                    ? <CircularProgress className="full_screen"/> : null}
                <div className="sign_in">
                    <form
                        noValidate
                        autoComplete="off"
                        className="form"
                        onKeyPress={this.handleKeyPress}
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}>
                        <h2>{variables[this.props.lang]['sign_in']}</h2>
                        {this.state.sendOTP
                            ? <div className="email_field otp_field">
                                <p>{variables[this.props.lang].otp_content}</p>
                                <OTPTextField/>
                                <Counter handleResend={this.handleOTP} lang={this.props.lang}/>
                            </div>
                            : <div className="email_field">
                                <EmailTextField/>
                                <p>{variables[this.props.lang]['email_content']}</p>
                            </div>}
                        {this.state.sendOTP
                            ? <div className="sign_in_action">
                                <Button
                                    className="verify_button"
                                    disabled={otpDisabled}
                                    onClick={this.handleVerify}>
                                    {variables[this.props.lang].verify}
                                </Button>
                            </div>
                            : <div className="sign_in_action">
                                <Button
                                    disabled={this.props.email === ''}
                                    onClick={this.handleOTP}>
                                    {variables[this.props.lang]['send_otp']}
                                </Button>
                            </div>}
                    </form>
                </div>
            </Dialog>
        );
    }
}

SignIn.propTypes = {
    eligibilityCheckLogin: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchEligibilityCheck: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    fetchProfileImage: PropTypes.func.isRequired,
    fetchStats: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    otp: PropTypes.string.isRequired,
    router: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        navigate: PropTypes.func.isRequired,
    }).isRequired,
    setEmail: PropTypes.func.isRequired,
    setTabValue: PropTypes.func.isRequired,
    showAdSlotConfirmDialog: PropTypes.func.isRequired,
    showEligibilityCheckDialog: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    signInData: PropTypes.object.isRequired,
    signInVerify: PropTypes.func.isRequired,
    ticketID: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        email: state.signIn.email.value,
        otp: state.signIn.otp,
        open: state.signIn.dialog,
        signInData: state.signIn.signIn.data,
        inProgress: state.signIn.signIn.inProgress,
        eligibilityCheckLogin: state.buyTickets.eligibilityCheckDialog.loginDialog,
        ticketID: state.buyTickets.eligibilityCheckDialog.id,
    };
};

const actionsToProps = {
    fetchBalance,
    fetchProfileDetails,
    fetchProfileImage,
    fetchStats,
    handleClose: hideSignInDialog,
    signIn,
    signInVerify,
    setEmail,
    fetchEligibilityCheck,
    showEligibilityCheckDialog,
    setTabValue,
    showAdSlotConfirmDialog,
};

export default withRouter(connect(stateToProps, actionsToProps)(SignIn));
