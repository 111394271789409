import { combineReducers } from 'redux';
import { CLAIM_NFT_TAB_SET, ENTRIES_LIST_FETCH_ERROR, ENTRIES_LIST_FETCH_IN_PROGRESS, ENTRIES_LIST_FETCH_SUCCESS, POAP_NFTS_LIST_FETCH_ERROR, POAP_NFTS_LIST_FETCH_IN_PROGRESS, POAP_NFTS_LIST_FETCH_SUCCESS, SELECT_ENTRY_VALUE_SET } from '../constants/poapNft';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../config';

const entriesList = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ENTRIES_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ENTRIES_LIST_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case ENTRIES_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const poapNftsList = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case POAP_NFTS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case POAP_NFTS_LIST_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case POAP_NFTS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const claimNftTab = (state = 'ticket', action) => {
    switch (action.type) {
    case CLAIM_NFT_TAB_SET:
        return action.value;

    default:
        return state;
    }
};

const selectEntryValue = (state = '', action) => {
    switch (action.type) {
    case SELECT_ENTRY_VALUE_SET:
        return action.value;

    default:
        return state;
    }
};

export default combineReducers({
    entriesList,
    claimNftTab,
    selectEntryValue,
    poapNftsList,
});
