import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import buyTickets from './buyTickets';
import account from './account';
import signIn from './signIn';
import myAccount from './myAccount';
import navBar from './navBar';
import ads from './ads';
import slotOrder from './slotOrder';
import socket from './socket';
import poapNft from './poapNft';

export default combineReducers({
    language,
    snackbar,
    buyTickets,
    account,
    signIn,
    myAccount,
    navBar,
    ads,
    slotOrder,
    socket,
    poapNft,
});
