export const CONNECT_KEPLR_ACCOUNT_IN_PROGRESS = 'CONNECT_KEPLR_ACCOUNT_IN_PROGRESS';
export const CONNECT_KEPLR_ACCOUNT_SUCCESS = 'CONNECT_KEPLR_ACCOUNT_SUCCESS';
export const CONNECT_KEPLR_ACCOUNT_ERROR = 'CONNECT_KEPLR_ACCOUNT_ERROR';

export const KEPLR_ACCOUNT_KEYS_SET = 'KEPLR_ACCOUNT_KEYS_SET';

export const CONNECT_COSMO_STATION_IN_PROGRESS = 'CONNECT_COSMO_STATION_IN_PROGRESS';
export const CONNECT_COSMO_STATION_SUCCESS = 'CONNECT_COSMO_STATION_SUCCESS';
export const CONNECT_COSMO_STATION_ERROR = 'CONNECT_COSMO_STATION_ERROR';

export const CONNECT_LEAP_ACCOUNT_IN_PROGRESS = 'CONNECT_LEAP_ACCOUNT_IN_PROGRESS';
export const CONNECT_LEAP_ACCOUNT_SUCCESS = 'CONNECT_LEAP_ACCOUNT_SUCCESS';
export const CONNECT_LEAP_ACCOUNT_ERROR = 'CONNECT_LEAP_ACCOUNT_ERROR';

export const CONNECT_FLIX_ACCOUNT_IN_PROGRESS = 'CONNECT_FLIX_ACCOUNT_IN_PROGRESS';
export const CONNECT_FLIX_ACCOUNT_SUCCESS = 'CONNECT_FLIX_ACCOUNT_SUCCESS';
export const CONNECT_FLIX_ACCOUNT_ERROR = 'CONNECT_FLIX_ACCOUNT_ERROR';

export const AMINO_SIGN_SUCCESS = 'AMINO_SIGN_SUCCESS';

export const BALANCE_FETCH_IN_PROGRESS = 'BALANCE_FETCH_IN_PROGRESS';
export const BALANCE_FETCH_SUCCESS = 'BALANCE_FETCH_SUCCESS';
export const BALANCE_FETCH_ERROR = 'BALANCE_FETCH_ERROR';

export const TX_HASH_IN_PROGRESS_FALSE_SET = 'TX_HASH_IN_PROGRESS_FALSE_SET';

export const DISCONNECT_SET = 'DISCONNECT_SET';
export const WALLET_DISCONNECT_SET = 'WALLET_DISCONNECT_SET';
export const FLIX_ACCOUNT_DISCONNECT_SET = 'FLIX_ACCOUNT_DISCONNECT_SET';
