import {
    AMINO_SIGN_SUCCESS,
    CONNECT_COSMO_STATION_ERROR,
    CONNECT_COSMO_STATION_IN_PROGRESS,
    CONNECT_COSMO_STATION_SUCCESS,
    CONNECT_FLIX_ACCOUNT_ERROR,
    CONNECT_FLIX_ACCOUNT_IN_PROGRESS,
    CONNECT_FLIX_ACCOUNT_SUCCESS,
    CONNECT_KEPLR_ACCOUNT_ERROR,
    CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    CONNECT_KEPLR_ACCOUNT_SUCCESS,
    CONNECT_LEAP_ACCOUNT_ERROR,
    CONNECT_LEAP_ACCOUNT_IN_PROGRESS,
    CONNECT_LEAP_ACCOUNT_SUCCESS,
    DISCONNECT_SET,
    FLIX_ACCOUNT_DISCONNECT_SET,
    KEPLR_ACCOUNT_KEYS_SET,
    TX_HASH_IN_PROGRESS_FALSE_SET,
    WALLET_DISCONNECT_SET,
} from '../../constants/wallet';
import {
    chainConfig,
    chainId,
    config,
    cosmoStationChainConfig,
    cosmoStationChainConfigFlix,
    flixChainConfig,
    flixConfig,
    walletExtensions,
} from '../../config';
import { SigningStargateClient } from '@cosmjs/stargate';
import { cosmos, InstallError } from '@cosmostation/extension-client';
import { getOfflineSigner } from '@cosmostation/cosmos-client';
import { makeSignDoc as AminoMakeSignDoc } from '@cosmjs/amino';

export const setDisconnect = () => {
    return {
        type: DISCONNECT_SET,
    };
};

export const setFlixAccountDisconnect = () => {
    return {
        type: FLIX_ACCOUNT_DISCONNECT_SET,
    };
};

export const setWalletDisconnect = () => {
    return {
        type: WALLET_DISCONNECT_SET,
    };
};

const connectKeplrAccountInProgress = () => {
    return {
        type: CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    };
};

const connectKeplrAccountSuccess = (value) => {
    return {
        type: CONNECT_KEPLR_ACCOUNT_SUCCESS,
        value,
    };
};

const connectKeplrAccountError = (message) => {
    return {
        type: CONNECT_KEPLR_ACCOUNT_ERROR,
        message,
    };
};

const setKeplrAccountKeys = (value) => {
    return {
        type: KEPLR_ACCOUNT_KEYS_SET,
        value,
    };
};

export const initializeChain = (cb) => (dispatch) => {
    dispatch(connectKeplrAccountInProgress());
    (async () => {
        if (!window.getOfflineSigner || !window.keplr) {
            const error = 'Please install keplr extension';
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                // Take the user to a different screen here.
                window.open('keplrwallet://wcV1');
            } else if (/Android/i.test(navigator.userAgent)) {
                window.open('intent://wcV1#Intent;package=com.chainapsis.keplr;scheme=keplrwallet;end;');
            } else {
                window.open(walletExtensions.KEPLR);
            }
            dispatch(connectKeplrAccountError(error));
        } else {
            if (window.keplr.experimentalSuggestChain) {
                try {
                    await window.keplr.experimentalSuggestChain(chainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectKeplrAccountError(chainError));
                }
            } else {
                const versionError = 'Please use the recent version of keplr extension';
                dispatch(connectKeplrAccountError(versionError));
            }
        }

        if (window.keplr) {
            window.keplr.enable(chainId)
                .then(async () => {
                    const offlineSigner = window.getOfflineSigner(chainId);
                    const accounts = await offlineSigner.getAccounts();
                    dispatch(connectKeplrAccountSuccess(accounts));
                    cb(accounts);
                }).catch((error) => {
                    dispatch(connectKeplrAccountError(error.toString()));
                });
        } else {
            return null;
        }
    })();
};

const connectFlixAccountInProgress = () => {
    return {
        type: CONNECT_FLIX_ACCOUNT_IN_PROGRESS,
    };
};

const connectFlixAccountSuccess = (value) => {
    return {
        type: CONNECT_FLIX_ACCOUNT_SUCCESS,
        value,
    };
};

const connectFlixAccountError = (message) => {
    return {
        type: CONNECT_FLIX_ACCOUNT_ERROR,
        message,
    };
};

export const initializeFlixChain = (cb) => (dispatch) => {
    dispatch(connectFlixAccountInProgress());
    (async () => {
        if (!window.getOfflineSigner || !window.keplr) {
            const error = 'Please install keplr extension';
            dispatch(connectFlixAccountError(error));
        } else {
            if (window.keplr.experimentalSuggestChain) {
                try {
                    await window.keplr.experimentalSuggestChain(flixChainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectFlixAccountError(chainError));
                }
            } else {
                const versionError = 'Please use the recent version of keplr extension';
                dispatch(connectFlixAccountError(versionError));
            }
        }

        if (window.keplr) {
            window.keplr.enable(flixConfig.CHAIN_ID)
                .then(async () => {
                    const offlineSigner = window.getOfflineSigner(flixConfig.CHAIN_ID);
                    const accounts = await offlineSigner.getAccounts();
                    dispatch(connectFlixAccountSuccess(accounts));
                    cb(accounts);
                }).catch((error) => {
                    dispatch(connectFlixAccountError(error.toString()));
                });
        } else {
            return null;
        }
    })();
};

export const initializeCosmoStationFlixChain = (cb) => (dispatch) => {
    dispatch(connectFlixAccountInProgress());
    (async () => {
        if (!window.getOfflineSigner || !window.cosmostation) {
            const error = 'Please install Cosmostation extension';
            dispatch(connectFlixAccountError(error));
        } else {
            const provider = await cosmos();
            const supportedChains = await provider.getSupportedChains();
            const official = supportedChains && supportedChains.official.find((val) => val === flixConfig.CHAIN_NAME.toLocaleLowerCase());
            const unOfficial = supportedChains && supportedChains.unofficial.find((val) => val === flixConfig.CHAIN_NAME.toLocaleLowerCase());
            if (!official && !unOfficial) {
                try {
                    await provider.addChain(cosmoStationChainConfigFlix);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectFlixAccountError(chainError));
                }
            }
        }
        try {
            const provider = await cosmos();
            const account = await provider.requestAccount(flixConfig.CHAIN_NAME);
            dispatch(connectFlixAccountSuccess(account));
            cb(null, account);
        } catch (error) {
            if (error instanceof InstallError) {
                const error = 'Download the Cosmostation Extension';
                window.open(walletExtensions.COSMO_STATION);
                dispatch(connectFlixAccountError(error));
                cb(error);
            } else if (error.code === 4001) {
                const error = 'user rejected request';
                dispatch(connectFlixAccountError(error));
                cb(error);
            } else {
                dispatch(connectFlixAccountError(error.message));
                cb(error.message);
            }
        }
    })();
};

export const initializeLeapFlixChain = (cb) => (dispatch) => {
    dispatch(connectFlixAccountInProgress());
    (async () => {
        if (!window.leap || (window.leap && !window.leap.getOfflineSigner)) {
            const error = 'Please install leap extension';
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                // Take the user to a different screen here.
                window.open('https://leapcosmoswallet.page.link/xBBqcKCAqEZHMWke9');
            } else {
                window.open(walletExtensions.LEAP);
            }
            dispatch(connectFlixAccountError(error));
        } else {
            if (window.leap.experimentalSuggestChain) {
                try {
                    await window.leap.experimentalSuggestChain(flixChainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectFlixAccountError(chainError));
                }
            } else {
                const versionError = 'Please use the recent version of Leap extension';
                dispatch(connectFlixAccountError(versionError));
            }
        }

        if (window.leap) {
            window.leap.enable(flixConfig.CHAIN_ID)
                .then(async () => {
                    const offlineSigner = window.leap.getOfflineSigner(flixConfig.CHAIN_ID);
                    const accounts = await offlineSigner.getAccounts();
                    dispatch(connectFlixAccountSuccess(accounts));
                    cb(accounts);

                    window.leap.getKey(flixConfig.CHAIN_ID).then((res) => {
                        dispatch(setKeplrAccountKeys(res));
                    }).catch(() => {

                    });
                }).catch((error) => {
                    dispatch(connectFlixAccountError(error.toString()));
                });
        } else {
            return null;
        }
    })();
};

const aminoSignSuccess = (value) => {
    return {
        type: AMINO_SIGN_SUCCESS,
        value: {},
    };
};

export const aminoSignTx = (type, tx, address, cb) => (dispatch) => {
    dispatch(connectKeplrAccountInProgress());
    (async () => {
        let offlineSigner;
        if (type === 'leap') {
            await window.leap && window.leap.enable(flixConfig.CHAIN_ID);
            offlineSigner = window.leap.getOfflineSignerOnlyAmino && window.leap.getOfflineSignerOnlyAmino(flixConfig.CHAIN_ID);
        } else if (type === 'cosmostation') {
            offlineSigner = await getOfflineSigner(flixConfig.CHAIN_NAME);
        } else {
            await window.keplr && window.keplr.enable(flixConfig.CHAIN_ID);
            offlineSigner = window.getOfflineSignerOnlyAmino && window.getOfflineSignerOnlyAmino(flixConfig.CHAIN_ID);
        }

        try {
            const client = await SigningStargateClient.connectWithSigner(
                flixConfig.RPC_URL,
                offlineSigner,
            );

            const account = {};
            try {
                const {
                    accountNumber,
                    sequence,
                } = await client.getSequence(address);
                account.accountNumber = accountNumber;
                account.sequence = sequence;
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }

            const signDoc = AminoMakeSignDoc(
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                flixConfig.CHAIN_ID,
                tx.memo,
                account.accountNumber,
                account.sequence,
            );

            offlineSigner.signAmino(address, signDoc).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(connectKeplrAccountError(result.log || result.rawLog));
                    cb(null);
                } else {
                    dispatch(aminoSignSuccess(result));
                    cb(result);
                }
            }).catch((error) => {
                dispatch(connectKeplrAccountError(error && error.message));
                cb(null);
            });
        } catch (e) {
            dispatch(connectKeplrAccountError(e && e.message));
            cb(null);
        }
    })();
};

export const signTxAndBroadcast = (tx, address, cb) => (dispatch) => {
    dispatch(connectKeplrAccountInProgress());

    (async () => {
        let offlineSigner;
        if (localStorage.getItem('cosmoverse_2024_leap')) {
            await window.leap && window.leap.enable(chainId);
            offlineSigner = window.leap.getOfflineSignerOnlyAmino && window.leap.getOfflineSignerOnlyAmino(chainId);
        } else {
            await window.keplr && window.keplr.enable(chainId);
            offlineSigner = window.getOfflineSignerOnlyAmino && window.getOfflineSignerOnlyAmino(chainId);
        }
        const client = await SigningStargateClient.connectWithSigner(
            config.RPC_URL,
            offlineSigner,
        );

        client.signAndBroadcast(
            address,
            tx.msgs ? tx.msgs : [tx.msg],
            tx.fee,
            tx.memo,
        ).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                dispatch(connectKeplrAccountError(result.log || result.rawLog));
                cb(result.log || result.rawLog);
            } else {
                dispatch(aminoSignSuccess(result));
                cb(null, result);
            }
        }).catch((error) => {
            dispatch(connectKeplrAccountError(error && error.message));
            cb(error && error.message);
        });
    })();
};

export const signCosmostationTxAndBroadcast = (tx, address, cb) => (dispatch) => {
    dispatch(connectKeplrAccountInProgress());

    (async () => {
        const offlineSigner = await getOfflineSigner(chainId);
        const client = await SigningStargateClient.connectWithSigner(
            config.RPC_URL,
            offlineSigner,
        );

        client.signAndBroadcast(
            address,
            tx.msgs ? tx.msgs : [tx.msg],
            tx.fee,
            tx.memo,
        ).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                dispatch(connectKeplrAccountError(result.log || result.rawLog));
                cb(result.log || result.rawLog);
            } else {
                dispatch(aminoSignSuccess(result));
                cb(null, result);
            }
        }).catch((error) => {
            dispatch(connectKeplrAccountError(error && error.message));
            cb(error && error.message);
        });
    })();
};

export const setTxHashInProgressFalse = () => {
    return {
        type: TX_HASH_IN_PROGRESS_FALSE_SET,
    };
};

const connectCosmoStationInProgress = () => {
    return {
        type: CONNECT_COSMO_STATION_IN_PROGRESS,
    };
};

const connectCosmoStationSuccess = (value) => {
    return {
        type: CONNECT_COSMO_STATION_SUCCESS,
        value,
    };
};

const connectCosmoStationError = (message) => {
    return {
        type: CONNECT_COSMO_STATION_ERROR,
        message,
        variant: 'error',
    };
};

export const initializeCosmoStation = (cb) => (dispatch) => {
    dispatch(connectCosmoStationInProgress());
    (async () => {
        if (!window.getOfflineSigner || !window.cosmostation) {
            const error = 'Please install Cosmostation extension';
            dispatch(connectCosmoStationError(error));
        } else {
            const provider = await cosmos();
            const supportedChains = await provider.getSupportedChains();
            const official = supportedChains && supportedChains.official.find((val) => val === config.CHAIN_NAME.toLocaleLowerCase());
            const unOfficial = supportedChains && supportedChains.unofficial.find((val) => val === config.CHAIN_NAME.toLocaleLowerCase());
            if (!official && !unOfficial) {
                try {
                    await provider.addChain(cosmoStationChainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectCosmoStationError(chainError));
                }
            }
        }
        try {
            const provider = await cosmos();
            const account = await provider.requestAccount(config.CHAIN_ID);
            dispatch(connectCosmoStationSuccess(account));
            cb(null, account);
        } catch (error) {
            if (error instanceof InstallError) {
                const error = 'Download the Cosmostation Extension';
                window.open(walletExtensions.COSMO_STATION);
                dispatch(connectCosmoStationError(error));
                cb(error);
            } else if (error.code === 4001) {
                const error = 'user rejected request';
                dispatch(connectCosmoStationError(error));
                cb(error);
            } else {
                dispatch(connectCosmoStationError(error.message));
                cb(error.message);
            }
        }
    })();
};

const connectLeapAccountInProgress = () => {
    return {
        type: CONNECT_LEAP_ACCOUNT_IN_PROGRESS,
    };
};

const connectLeapAccountSuccess = (value) => {
    return {
        type: CONNECT_LEAP_ACCOUNT_SUCCESS,
        value,
    };
};

const connectLeapAccountError = (message) => {
    return {
        type: CONNECT_LEAP_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const initializeLeap = (cb) => (dispatch) => {
    dispatch(connectLeapAccountInProgress());
    (async () => {
        if (!window.leap || (window.leap && !window.leap.getOfflineSigner)) {
            const error = 'Please install leap extension';
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                // Take the user to a different screen here.
                window.open('https://leapcosmoswallet.page.link/xBBqcKCAqEZHMWke9');
            } else {
                window.open(walletExtensions.LEAP);
            }
            dispatch(connectLeapAccountError(error));
        } else {
            if (window.leap.experimentalSuggestChain) {
                try {
                    await window.leap.experimentalSuggestChain(chainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectLeapAccountError(chainError));
                }
            } else {
                const versionError = 'Please use the recent version of Leap extension';
                dispatch(connectLeapAccountError(versionError));
            }
        }

        if (window.leap) {
            window.leap.enable(chainId)
                .then(async () => {
                    const offlineSigner = window.leap.getOfflineSigner(chainId);
                    const accounts = await offlineSigner.getAccounts();
                    dispatch(connectLeapAccountSuccess(accounts));
                    cb(accounts);

                    window.leap.getKey(chainId).then((res) => {
                        dispatch(setKeplrAccountKeys(res));
                    }).catch(() => {

                    });
                }).catch((error) => {
                    dispatch(connectLeapAccountError(error.toString()));
                });
        } else {
            return null;
        }
    })();
};
