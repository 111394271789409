import { combineReducers } from 'redux';
import {
    BC_CONNECT_DIALOG_HIDE,
    BC_CONNECT_DIALOG_SHOW,
    CLAIM_ADDRESS_SET,
    CLAIM_DIALOG_HIDE,
    CLAIM_DIALOG_SHOW,
    CLAIM_NFT_ERROR,
    CLAIM_NFT_IN_PROGRESS,
    CLAIM_NFT_SUCCESS,
    CLAIM_STATUS_FETCH_ERROR,
    CLAIM_STATUS_FETCH_IN_PROGRESS,
    CLAIM_STATUS_FETCH_SUCCESS,
    CLAIMED_LIST_FETCH_ERROR,
    CLAIMED_LIST_FETCH_IN_PROGRESS,
    CLAIMED_LIST_FETCH_SUCCESS,
    COLLECTION_NFTS_FETCH_ERROR,
    COLLECTION_NFTS_FETCH_IN_PROGRESS,
    COLLECTION_NFTS_FETCH_SUCCESS,
    DROPS_TAB_SET,
    ELIGIBLE_CLAIM_DIALOG_HIDE,
    ELIGIBLE_CLAIM_DIALOG_SHOW,
    ELIGIBLE_CLAIM_NFT_SET,
    ELIGIBLE_CLAIM_TYPE_SET,
    ELIGIBLE_LIST_FETCH_ERROR,
    ELIGIBLE_LIST_FETCH_IN_PROGRESS,
    ELIGIBLE_LIST_FETCH_SUCCESS,
    FETCH_PROFILE_IMAGE_ERROR,
    FETCH_PROFILE_IMAGE_IN_PROGRESS,
    FETCH_PROFILE_IMAGE_SUCCESS,
    NFT_S_FETCH_ERROR,
    NFT_S_FETCH_IN_PROGRESS,
    NFT_S_FETCH_SUCCESS,
    ORDERS_FETCH_ERROR,
    ORDERS_FETCH_IN_PROGRESS,
    ORDERS_FETCH_SUCCESS,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    QR_CODE_FETCH_ERROR,
    QR_CODE_FETCH_IN_PROGRESS,
    QR_CODE_FETCH_SUCCESS,
    STATS_FETCH_ERROR,
    STATS_FETCH_IN_PROGRESS,
    STATS_FETCH_SUCCESS,
    TAB_VALUE_SET,
    TICKET_CANCEL_DIALOG_HIDE,
    TICKET_CANCEL_DIALOG_SHOW,
    TICKET_CANCEL_ERROR,
    TICKET_CANCEL_IN_PROGRESS,
    TICKET_CANCEL_SUCCESS,
    TICKET_DOWNLOAD_ERROR,
    TICKET_DOWNLOAD_IN_PROGRESS,
    TICKET_DOWNLOAD_SUCCESS,
    TICKET_FETCH_ERROR,
    TICKET_FETCH_IN_PROGRESS,
    TICKET_FETCH_SUCCESS,
    TICKETS_FETCH_ERROR,
    TICKETS_FETCH_IN_PROGRESS,
    TICKETS_FETCH_SUCCESS,
    TYPES_FETCH_ERROR,
    TYPES_FETCH_IN_PROGRESS,
    TYPES_FETCH_SUCCESS,
    TRANSFER_MY_TICKET_DIALOG_HIDE,
    TRANSFER_MY_TICKET_DIALOG_SHOW,
    TRANSFER_MY_TICKET_ERROR,
    TRANSFER_MY_TICKET_IN_PROGRESS,
    TRANSFER_MY_TICKET_SUCCESS,
    UPDATE_NAME_SET,
    UPDATE_NAME_VALUE_SET,
    UPDATE_PROFILE_ERROR,
    UPDATE_PROFILE_IMAGE_ERROR,
    UPDATE_PROFILE_IMAGE_IN_PROGRESS,
    UPDATE_PROFILE_IMAGE_SUCCESS,
    UPDATE_PROFILE_IN_PROGRESS,
    UPDATE_PROFILE_SUCCESS,
    ALL_DROPS_FETCH_IN_PROGRESS,
    ALL_DROPS_FETCH_SUCCESS,
    ALL_DROPS_FETCH_ERROR,
    SAME_BC_CONNECT_ERROR_SET,
    SAME_BC_CONNECT_ERROR_SET_FALSE,
    CONFIRM_SWITCH_DIALOG_SHOW,
    CONFIRM_SWITCH_DIALOG_HIDE,
    CLAIMABLE_TICKETS_FETCH_IN_PROGRESS,
    CLAIMABLE_TICKETS_FETCH_SUCCESS,
    CLAIMABLE_TICKETS_FETCH_ERROR,
    CLAIMABLE_TICKET_SET,
    NFTS_TAB_VALUE_SET,
} from '../constants/myAccount';
import { DISCONNECT_SET, FLIX_ACCOUNT_DISCONNECT_SET } from '../constants/wallet';
import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../config';

const tabValue = (state = 'tickets', action) => {
    switch (action.type) {
    case TAB_VALUE_SET:
        return action.value;

    default:
        return state;
    }
};

const updateName = (state = false, action) => {
    switch (action.type) {
    case UPDATE_NAME_SET:
        return action.value;

    default:
        return state;
    }
};

const updateNameValue = (state = '', action) => {
    switch (action.type) {
    case UPDATE_NAME_VALUE_SET:
        return action.value;
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return action.value && action.value.name ? action.value.name : '';

    default:
        return state;
    }
};

const profileDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PROFILE_DETAILS_FETCH_IN_PROGRESS:
    case UPDATE_PROFILE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_DETAILS_FETCH_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PROFILE_DETAILS_FETCH_ERROR:
    case UPDATE_PROFILE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const orders = (state = {
    inProgress: false,
    value: [],
    total: null,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case ORDERS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ORDERS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case ORDERS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
            total: null,
        };
    default:
        return state;
    }
};

const tickets = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case TICKETS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TICKETS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case TICKETS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
            total: null,
        };
    default:
        return state;
    }
};

const ticket = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TICKET_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TICKET_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    }
    case TICKET_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const cancelTicket = (state = {
    inProgress: false,
    value: {},
    dialog: false,
    id: '',
}, action) => {
    switch (action.type) {
    case TICKET_CANCEL_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TICKET_CANCEL_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TICKET_CANCEL_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case TICKET_CANCEL_DIALOG_SHOW:
        return {
            ...state,
            dialog: true,
            id: action.id,
        };
    case TICKET_CANCEL_DIALOG_HIDE:
        return {
            ...state,
            dialog: false,
            id: '',
        };
    default:
        return state;
    }
};

const profileImage = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_PROFILE_IMAGE_IN_PROGRESS:
    case UPDATE_PROFILE_IMAGE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROFILE_IMAGE_SUCCESS:
    case UPDATE_PROFILE_IMAGE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_PROFILE_IMAGE_ERROR:
    case UPDATE_PROFILE_IMAGE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const ticketDownload = (state = {
    inProgress: false,
    id: '',
}, action) => {
    switch (action.type) {
    case TICKET_DOWNLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            id: action.id,
        };
    case TICKET_DOWNLOAD_SUCCESS: {
        return {
            ...state,
            inProgress: false,
        };
    }
    case TICKET_DOWNLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const stats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case STATS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case STATS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case STATS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const qrCodes = (state = {
    inProgress: false,
    images: [],
}, action) => {
    switch (action.type) {
    case QR_CODE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case QR_CODE_FETCH_SUCCESS: {
        const array = [...state.images];
        const filter = array.filter((val) => val.id && action.value && action.value.id && action.value.id === val.id);
        if (filter && filter.length) {
            return {
                ...state,
                inProgress: false,
            };
        }

        array.push(action.value);
        return {
            ...state,
            inProgress: false,
            images: [...array],
        };
    }
    case QR_CODE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const claimStatus = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CLAIM_STATUS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CLAIM_STATUS_FETCH_SUCCESS: {
        const obj = { ...state.value };
        obj[action.id] = action.value;

        return {
            inProgress: false,
            value: obj,
        };
    }
    case CLAIM_STATUS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const claimDialog = (state = {
    open: false,
    // id: '',
}, action) => {
    switch (action.type) {
    case CLAIM_DIALOG_SHOW:
        return {
            open: true,
            // id: action.id,
        };
    case CLAIM_DIALOG_HIDE:
        return {
            open: false,
            // id: '',
        };
    default:
        return state;
    }
};

const claimAddress = (state = '', action) => {
    switch (action.type) {
    case CLAIM_ADDRESS_SET:
        return action.value;
    case PROFILE_DETAILS_FETCH_SUCCESS: {
        if (action.value && action.value.bc_account && action.value.bc_account.address) {
            return action.value.bc_account.address;
        }

        return state;
    }
    case FLIX_ACCOUNT_DISCONNECT_SET:
    case CLAIM_DIALOG_HIDE:
        return '';

    default:
        return state;
    }
};

const nftClaim = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CLAIM_NFT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CLAIM_NFT_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    }
    case CLAIM_NFT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const nfts = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case NFT_S_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NFT_S_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case NFT_S_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
            total: null,
        };
    default:
        return state;
    }
};

const connectDialog = (state = {
    open: false,
    showTooltip: false,
    error: false,
}, action) => {
    switch (action.type) {
    case BC_CONNECT_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            showTooltip: action.showTooltip,
        };
    case BC_CONNECT_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            showTooltip: false,
        };
    case SAME_BC_CONNECT_ERROR_SET:
        return {
            open: false,
            showTooltip: false,
            error: true,
        };
    case SAME_BC_CONNECT_ERROR_SET_FALSE:
        return {
            ...state,
            error: false,
        };
    default:
        return state;
    }
};

const transferMyTicketDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TRANSFER_MY_TICKET_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case TRANSFER_MY_TICKET_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const transferTicket = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TRANSFER_MY_TICKET_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TRANSFER_MY_TICKET_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    }
    case TRANSFER_MY_TICKET_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const dropsTabValue = (state = 'all', action) => {
    switch (action.type) {
    case DROPS_TAB_SET:
        return action.value;

    default:
        return state;
    }
};

const eligibleList = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ELIGIBLE_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ELIGIBLE_LIST_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case ELIGIBLE_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
            total: null,
        };
    default:
        return state;
    }
};

const claimedList = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case CLAIMED_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CLAIMED_LIST_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case CLAIMED_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
            total: null,
        };
    default:
        return state;
    }
};

const eligibleClaimDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ELIGIBLE_CLAIM_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case ELIGIBLE_CLAIM_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const eligibleClaimType = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case ELIGIBLE_CLAIM_TYPE_SET:
        return {
            value: action.value,
        };
    case ELIGIBLE_CLAIM_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const eligibleClaimNft = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case ELIGIBLE_CLAIM_NFT_SET:
        return {
            value: action.value,
        };
    case ELIGIBLE_CLAIM_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const collectionNFTs = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
    search: '',
}, action) => {
    switch (action.type) {
    case COLLECTION_NFTS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COLLECTION_NFTS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
                search: action.search,
            };
        }
    case COLLECTION_NFTS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const typesList = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case TYPES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TYPES_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case TYPES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const claimableTickets = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case CLAIMABLE_TICKETS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CLAIMABLE_TICKETS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case CLAIMABLE_TICKETS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const claimableTicket = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case CLAIMABLE_TICKET_SET:
        return {
            value: action.value,
        };
    case CLAIM_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const claimNFT = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CLAIM_NFT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CLAIM_NFT_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    }
    case CLAIM_NFT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const allDropsList = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ALL_DROPS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ALL_DROPS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case ALL_DROPS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const confirmSwitchDialog = (state = {
    value: '',
    open: false,
}, action) => {
    switch (action.type) {
    case CONFIRM_SWITCH_DIALOG_SHOW:
        return {
            value: action.value,
            open: true,
        };
    case CONFIRM_SWITCH_DIALOG_HIDE:
        return {
            value: '',
            open: false,
        };
    default:
        return state;
    }
};

const nftsTabValue = (state = 'ticket', action) => {
    switch (action.type) {
    case NFTS_TAB_VALUE_SET:
        return action.value;

    default:
        return state;
    }
};

export default combineReducers({
    tabValue,
    updateName,
    updateNameValue,
    profileDetails,
    orders,
    tickets,
    ticket,
    cancelTicket,
    profileImage,
    ticketDownload,
    stats,
    qrCodes,
    claimStatus,
    claimDialog,
    claimAddress,
    nftClaim,
    nfts,
    connectDialog,

    transferMyTicketDialog,
    transferTicket,

    dropsTabValue,
    eligibleList,
    claimedList,
    eligibleClaimDialog,
    eligibleClaimType,
    eligibleClaimNft,
    collectionNFTs,
    typesList,
    claimableTickets,
    claimableTicket,
    claimNFT,
    allDropsList,
    confirmSwitchDialog,

    nftsTabValue,
});
